import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { NoteType, UserType } from '../../../../__generated__/graphql';
import { NOTE_MUTATION } from '../../../../api/mutations/notes';
import { NOTES_QUERY } from '../../../../api/queries/notes';
import { useAppDispatch } from '../../../../helpers/reduxHooks';
import { getInitials } from '../../../../helpers/utils';
import { setSuccessAlert } from '../../../../redux/alertSlice';
import { setRequestUpdate } from '../../../../redux/notesSlice';

interface Props {
  item: NoteType,
  user: UserType,
}

export default function UpdateNote(props: Props) {
  const { item, user } = props;
  const { instanceId } = useParams();
  const dispatch = useAppDispatch();
  const [
    noteMutation,
    {
      data: noteData,
    }] = useMutation(NOTE_MUTATION, {
    refetchQueries: [
      { query: NOTES_QUERY, variables: { instance: instanceId } },
    ],
  });

  const schema = yup.object({
    body: yup.string().required('Requried'),
  });

  const {
    handleSubmit,
    handleChange,
    values: formikValues,
    resetForm,
  } = useFormik({
    initialValues: {
      body: item.body,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        id: item.id,
        instance: instanceId,
        body: values.body,
        author: user.id,
      };
      noteMutation({
        variables,
      });
      resetForm();
    },
  });

  useEffect(() => {
    if (
      noteData
      && noteData.note
      && noteData.note.response
    ) {
      dispatch(setRequestUpdate(false));
      dispatch(
        setSuccessAlert({
          messages: ['The Note was updated'],
          instanceId: instanceId as string,
        }),
      );
    }
  }, [noteData, dispatch, instanceId]);
  
  return (
    <div className="px-4 sm:space-y-0 sm:gap-4 sm:px-6 sm:py-4">
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <span className="bg-orange-500 shadow-lg inline-flex items-center justify-center h-8 w-8 rounded">
            <span className="text-sm font-medium leading-none text-white">{getInitials(user.username as string)}</span>
          </span>
        </div>
        <div className="min-w-0 flex-1">
          <div className="flex-1 space-y-1">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">{user.username}</h3>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="relative pt-4">
            <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-orange-500 focus-within:ring-1 focus-within:ring-orange-500">
              <textarea
                rows={20}
                name="body"
                id="styled-scroll"
                className="overflow-y-auto block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                placeholder="Add note..."
                value={formikValues.body}
                onChange={handleChange}
              />
            </div>

            <div className="bg-lightGray px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Confirm
              </button>
              <button
                type="button"
                onClick={() => dispatch(setRequestUpdate(false))}
                className="mt-3 w-full inline-flex justify-center rounded-md border-2 border-darkerGray shadow-sm px-4 py-2 bg-white text-base font-medium  hover:bg-slate-100  0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
