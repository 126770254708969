import ErrorPageTempate from './ErrorPageTempate';

function Error401() {
  return (
    <ErrorPageTempate
      label="Error: Unauthorized."
      code={401}
    />
  );
}

export default Error401;
