import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { ServerType } from '../../../__generated__/graphql';
import { SERVERS_QUERY } from '../../../api/queries/servers';
import { useAppSelector, useAppDispatch } from '../../../helpers/reduxHooks';
import {
  updateSelectTheme,
} from '../../../helpers/utils';
import { LoadingIndicator } from '../../../layout';
import { setServerDomain } from '../../../redux/instancesQueryAttributesSlice';

function getSelectValue(items: ServerType[], domain: string | undefined) {
  const data = items.find((item) => item.domain === domain);
  if (!data) return null;
  return {
    value: data.domain,
    label: data.serverLabel,
  };
}

function ServerSelector() {
  const dispatch = useAppDispatch();
  const instancesQueryAttributesState = useAppSelector(state => state.instancesQueryAttributes);
  const { data: serversData } = useQuery(SERVERS_QUERY);

  return (
    <div className="mt-1">
      {serversData && serversData.servers.length ? (
        <Select
          value={getSelectValue(serversData.servers, instancesQueryAttributesState.serverDomain)}
          name="server"
          placeholder="Filter by Server"
          options={serversData.servers.map((item: ServerType) => ({
            value: item.domain,
            label: item.serverLabel,
          }))}
          isSearchable={false}
          isClearable
          onChange={(e) => (e ? dispatch(setServerDomain(e.value)) : dispatch(setServerDomain()))}
          theme={(theme) => updateSelectTheme(theme)}
          styles={{
            control: (base) => ({
              ...base,
              fontSize: 14,
              paddingTop: 2,
              paddingBottom: 2,
            }),
            menuList: (base) => ({
              ...base,
              fontSize: 14,
            }),
            placeholder: (base) => ({
              ...base,
              color: '#64748b',
            }),
          }}
        />
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
}

export default ServerSelector;
