import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import {
  INSTANCES_QUANTITY_QUERY,
  PAGINATED_INSTANCES_QUERY,
} from '../api/queries/instances';
import { USER_QUERY } from '../api/queries/users';
import { Dashboard, DashboardSidebar } from '../components/dashboard';
import { MobileHeader, MobileSidebar } from '../components/dashboard/mobile';
import config from '../config';
import { clearAuthCookies } from '../helpers/auth';
import { useAppSelector } from '../helpers/reduxHooks';
import { classNames } from '../helpers/utils';

export default function Home() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { tab } = useParams();
  const instancesQueryAttributesState = useAppSelector(state => state.instancesQueryAttributes);
  const { data: userData, error: userError } = useQuery(USER_QUERY);
  const {
    data: instancesQuantityData,
  } = useQuery(INSTANCES_QUANTITY_QUERY, {
    pollInterval: config.REFETCH_DASHBOARD_ITEMS_EVERY,
  });

  const
    {
      data: instancesData,
      loading: instancesLoading,
    } = useQuery(
      PAGINATED_INSTANCES_QUERY,
      {
        variables: {
          page: instancesQueryAttributesState.page,
          serverDomain: instancesQueryAttributesState.serverDomain,
          name: instancesQueryAttributesState.name,
          category: tab?.toUpperCase(),
        },
        context: {
          debounceKey: 'PAGINATED_INSTANCES_QUERY',
        },
      },
    );

  if (userError) {
    // checks that we can do API call to backend, means
    // JWT token is in place and it is valid.
    clearAuthCookies();
    return <Navigate to="/authentication/login/" />;
  }

  return (
    <div className="min-h-full">
      {userData && instancesQuantityData && (
        <>
          <MobileSidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            instancesQuantity={
              instancesQuantityData.instancesQuantity
              && instancesQuantityData.instancesQuantity.response
            }
          />
          <DashboardSidebar
            instancesQuantity={
              instancesQuantityData.instancesQuantity
              && instancesQuantityData.instancesQuantity.response
            }
            user={userData.user}
          />
        </>
      )}
      <div className={classNames(userData && userData.user && userData.user.hasManagersActivityAccess ? 'lg:pl-[24rem]' : 'lg:pl-[18rem]', 'flex flex-col')}>
        {/* Search header */}
        <MobileHeader
          setSidebarOpen={setSidebarOpen}
          user={userData && userData.user}
        />
        <main className="flex-1">
          <Dashboard
            items={instancesData
              && instancesData.paginatedInstances
              && instancesData.paginatedInstances.response}
            loading={instancesLoading}
            user={userData && userData.user}
          />
        </main>
      </div>
    </div>
  );
}
