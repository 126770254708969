import ErrorPageTempate from './ErrorPageTempate';

function Error503() {
  return (
    <ErrorPageTempate
      label="Error: Bad Gateway."
      code={503}
    />
  );
}

export default Error503;
