import { IconPlus } from '@tabler/icons-react';
import { classNames } from '../../../../helpers/utils';

interface Props {
  isSelected: boolean,
  handleCardClick: (fontId?: string) => void,
}

function AddFontCard(props: Props) {
  const { isSelected, handleCardClick } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => (handleCardClick())}
      //@ts-ignore
      className={classNames(isSelected && 'border-4 border-slate-300', 'flex rounded-lg')}
    >
      <div
        className="bg-yellow-500 flex-shrink-0 flex items-center justify-center w-16 h-16 text-white text-3xl font-medium rounded-l-md"
      >
        <IconPlus className="w-10 h-10 text-white" aria-hidden="true" />
      </div>

      <div className="flex-1 flex items-center justify-between border-t border-r border-b border-slate-200 bg-white rounded-r-md">
        <div className="cursor-pointer flex-1 px-4 py-2">
          Add New Font
        </div>
      </div>
    </div>
  );
}

export default AddFontCard;
