import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AlertType } from '../constants';

interface Alert {
  id: string,
  messages: string[],
  alertType: AlertType,
  show: boolean,
  instanceId?: ID | null,

}

interface AlertPayload {
  messages: string[],
  instanceId?: ID | null,
}

interface AlertState {
  alert: Alert | null
}

// Define the initial state using that type
const initialState: AlertState = {
  alert: null,
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    setErrorAlert: (state, action: PayloadAction<AlertPayload>) => {
      state.alert = {
        id: uuidv4(),
        messages: action.payload.messages || [],
        alertType: AlertType.ERROR,
        show: true,
        instanceId: action.payload.instanceId,
      };
    },
    setSuccessAlert: (state, action: PayloadAction<AlertPayload>) => {
      state.alert = {
        id: uuidv4(),
        messages: action.payload.messages || [],
        alertType: AlertType.SUCCESS,
        show: true,
        instanceId: action.payload.instanceId,
      };
    },
    setInfoAlert: (state, action: PayloadAction<AlertPayload>) => {
      state.alert = {
        id: uuidv4(),
        messages: action.payload.messages || [],
        alertType: AlertType.INFO,
        show: true,
        instanceId: action.payload.instanceId,
      };
    },
    discardAlert: (state) => {
      state.alert = null;
    },
  },
});

export const { setErrorAlert, setSuccessAlert, setInfoAlert, discardAlert } = alertSlice.actions;

export default alertSlice.reducer;
