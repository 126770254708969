import { useQuery } from '@apollo/client';
import { Navigate, useParams, Link } from 'react-router-dom';
import { USER_QUERY } from '../api/queries/users';
import { DashboardSidebar } from '../components/dashboard';
import { NoView, GlobalView, SingleView } from '../components/modxUsersManagement';
import { clearAuthCookies } from '../helpers/auth';
import { capitalize } from '../helpers/utils';

export default function MODXUsersManagement() {
  const { data: userData, error: userError } = useQuery(USER_QUERY);
  const { view } = useParams();

  if (userError || (userData && userData.user && !userData.user.hasModxUsersAccess)) {
    // checks that we can do API call to backend, means
    // JWT token is in place and it is valid.
    clearAuthCookies();
    return <Navigate to="/authentication/login/" />;
  }

  if (!(userData && userData.user)) {
    return '';
  }
  return (
    <div className="min-h-full">
      {userData && userData.user && (
        <DashboardSidebar
          user={userData.user}
        />
      )}

      <div className="flex flex-col pl-24">
        <main className="flex-1">
          <div className="flex py-6 items-center justify-between">
            <div className="text-xl font-medium leading-6 text-slate-900 sm:truncate">
              <Link to="/managers-management/">
                MODX Users Management
              </Link>
              {view && ` / ${capitalize(view)}`}
            </div>
          </div>
          <div className="bg-white">
            {!view && <NoView />}
            {view === 'global' && <GlobalView />}
            {view === 'single' && <SingleView />}
          </div>
        </main>
      </div>
    </div>
  );
}
