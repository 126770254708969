/* eslint-disable @typescript-eslint/no-explicit-any */
import { FixedSizeList as List } from 'react-window';

const OPTION_HEIGHT = 40;
const ROWS = 6;

function MenuList({
  options,
  children,
  getValue,
}: any) {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) !== -1
    ? Array.isArray(children)
        && children.length >= ROWS
      ? options.indexOf(value) >= ROWS
        ? options.indexOf(value)
              * OPTION_HEIGHT
            - OPTION_HEIGHT * 5
        : 0
      : 0
    : 0;

  return Array.isArray(children) ? (
    //@ts-ignore
    <List
      height={
        children.length >= ROWS
          ? OPTION_HEIGHT * ROWS
          : children.length * OPTION_HEIGHT
      }
      itemCount={children.length}
      itemSize={OPTION_HEIGHT}
      initialScrollOffset={initialOffset}
    >
      {({ style, index }) => (
        <div style={style} className="hover:bg-[#64748b] hover:text-white p-2 cursor-pointer">
          {children[index]}
        </div>
      )}
    </List>
  ) : (
    <div>{children}</div>
  );
}

export default MenuList;
