import { useMutation } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import {
  IconSearch, IconMenu2,
} from '@tabler/icons-react';
import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserType } from '../../../__generated__/graphql';
import { REVOKE_REFRESH_TOKEN_MUTATION } from '../../../api/mutations/users';
import { clearAuthCookies, getRefreshToken } from '../../../helpers/auth';
import { useAppDispatch } from '../../../helpers/reduxHooks';
import { classNames, getInitials } from '../../../helpers/utils';
import { setSearchName } from '../../../redux/instancesQueryAttributesSlice';

interface Props {
  setSidebarOpen: (value: boolean) => void,
  user: UserType,
}

function MobileHeader(props: Props) {
  const { setSidebarOpen, user } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchName(e && e.target.value));
  };

  const [revokeRefreshToken,
    { data: revokeRefreshTokenData },
  ] = useMutation(REVOKE_REFRESH_TOKEN_MUTATION);

  const handleLogout = () => {
    revokeRefreshToken({ variables: { refreshToken: getRefreshToken() } });
  };

  useEffect(() => {
    if (revokeRefreshTokenData) {
      clearAuthCookies();
      navigate('/authentication/login/');
    }
  }, [revokeRefreshTokenData, navigate]);

  return (
    <div className="sticky top-0 flex-shrink-0 flex h-16 bg-white border-b border-slate-200 lg:hidden">
      <button
        type="button"
        className="px-4 border-r border-slate-200 text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <IconMenu2 className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex-1 flex">
          <form className="w-full flex md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-slate-400 focus-within:text-slate-600">
              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                <IconSearch className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                name="search-field"
                className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-slate-900 placeholder-slate-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-slate-400 sm:text-sm"
                placeholder="Search"
                type="search"
                onChange={handleSearchInput}
              />
            </div>
          </form>
        </div>
        <div className="flex items-center">
          {/* Profile dropdown */}
          <Menu as="div" className="ml-3 relative">
            <div>
              <Menu.Button className="max-w-xs bg-white flex items-center text-sm">
                <span className="sr-only">Open user menu</span>
                {user && (
                  <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg bg-slate-500">
                    <span className="text-lg font-medium leading-none text-white">{getInitials(user.username as string)}</span>
                  </span>
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-slate-200 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        role="button"
                        tabIndex={0}
                        className={classNames(
                          active ? 'bg-slate-100 text-slate-900' : 'text-slate-700',
                          'block px-4 py-2 text-sm',
                        )}
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
