import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
} from '@tabler/icons-react';
import { useAppDispatch } from '../helpers/reduxHooks';
import {
  classNames,
} from '../helpers/utils';
import { setPage } from '../redux/instancesQueryAttributesSlice';

interface Props {
  page: number,
  hasNext: boolean,
  hasPrev: boolean,
  elidedPageRange: string[],
  omitBorder: boolean,
  omitNumbers?: boolean,
}

function Pagination(props: Props) {
  const {
    page, hasNext, hasPrev, elidedPageRange, omitBorder, omitNumbers,
  } = props;
  const dispatch = useAppDispatch();

  const handleClick = (item: number) => {
    if (!!Number(item) === true) {
      dispatch(setPage(Number(item)));
    }
  };
  return (
    <nav
      className={classNames(omitBorder ? '' : 'border-t', 'border-slate-400 px-4 flex pt-2 lg:pt-0 items-center justify-between lg:px-0')}
      aria-label="Pagination"
    >
      <div className="-mt-px w-0 flex-1 flex pl-2">
        {hasPrev && (
          <div
            role="button"
            tabIndex={0}
            onClick={() => handleClick(Number(page) - 1)}
            className={classNames(omitNumbers ? '' : 'border-t-2', 'text-slate-500 hover:text-slate-700 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium  hover:border-slate-400')}
          >
            <IconArrowNarrowLeft className="mr-1 h-5 w-5 " aria-hidden="true" />
            Previous
          </div>
        )}
      </div>

      {!omitNumbers && (
        <div className="-mt-px flex">
          {elidedPageRange.length > 1 && elidedPageRange.map((item, index) => (
            <div
              key={index}
              tabIndex={0}
              role="button"
              onClick={() => handleClick(Number(item))}
              className={classNames(
                item === page.toString()
                  ? 'border-slate-500 text-slate-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'
                  : 'border-transparent  hover:border-slate-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium',
                '',
              )}
            >
              {item}
            </div>
          ))}
        </div>
      )}

      <div className="-mt-px w-0 flex-1 flex justify-end pr-2">
        {hasNext && (
          <div
            role="button"
            tabIndex={0}
            onClick={() => handleClick(Number(page) + 1)}
            className={classNames(omitNumbers ? '' : 'border-t-2', 'text-slate-500 hover:text-slate-700 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium  hover:border-slate-400')}
          >
            Next
            <IconArrowNarrowRight className="ml-1 h-5 w-5 " aria-hidden="true" />
          </div>
        )}
      </div>
    </nav>
  );
}

export default Pagination;
