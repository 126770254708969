import TimeAgo from 'react-timeago';
import { NoteType, UserType } from '../../../../__generated__/graphql';
import { getInitials, classNames } from '../../../../helpers/utils';
import ActionOptions from './ActionOptions';

function convertTextWithNewLinesIntoDivs(inputText: string) {
  return inputText.split('\n').map((str, index) => <div key={index} className="pt-1">{str}</div>);
}

interface Props {
  item: NoteType,
  user: UserType,
}

export default function NoteCard(props: Props) {
  const { item, user } = props;

  return (
    <div className="flex space-x-3">
      <span className={classNames(user.username === item.author.username ? 'bg-orange-500' : 'bg-slate-500', 'shadow-lg inline-flex items-center justify-center h-8 w-8 rounded ')}>
        <span className="text-sm font-medium leading-none text-white">{getInitials(item.author.username as string)}</span>
      </span>
      <div className="flex-1 space-y-1">
        <div className="flex">
          <h3 className="text-sm font-medium">{item.author.username}</h3>
          <ActionOptions />
        </div>
        <div className="text-sm text-slate-500">
          {convertTextWithNewLinesIntoDivs(item.body)}
        </div>
        {/* @ts-ignore */}
        <p className="self-end pt-1 text-xs text-slate-400"><TimeAgo date={item.createdAt} /></p>
      </div>
    </div>
  );
}
