import { ManagerActivityRecordType, ManagerActivityType } from '../../__generated__/graphql';
import ActivityLog from './ActivityLog';

interface Props {
  items: ManagerActivityType[],
}

function ActivitiesLog(props: Props) {
  const { items } = props;
  return (
    <div>
      <div className="text-lg font-medium leading-6 text-slate-700 sm:truncate py-2">Activity per Instance</div>
      <div>
        {items && items.length
          ? items.map((item) => (
            <div key={item.id} className="py-2">
              <div className="text-slate-500 text-sm font-medium tracking-wide">{item.name}</div>
              <ActivityLog items={item.data as ManagerActivityRecordType[]} />
            </div>
          ))
          : <div className="text-slate-500">No data</div>}
      </div>
    </div>
  );
}

export default ActivitiesLog;
