import { useQuery } from '@apollo/client';
import { IconAlertTriangle } from '@tabler/icons-react';
import { DBA_ENABLED_INSTANCES_QUERY } from '../../api/queries/instances';

export default function ActiveDBAAlert() {
  const { data: instancesData } = useQuery(DBA_ENABLED_INSTANCES_QUERY);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const items: InstanceType<any>[] = (instancesData && instancesData.dbaEnabledInstances
    && instancesData.dbaEnabledInstances.response)
    ? instancesData.dbaEnabledInstances.response
    : [];

  const dbaWarningMessage = items.length > 1
    ? 'Caution. These instances have active Database Administration Panels:'
    : 'Caution. This instance has active Database Administration Panel:';

  if (!items.length) {
    return '';
  }
  return (
    <div className="bg-yellow-100 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <IconAlertTriangle className="h-5 w-5 text-yellow-700" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-yellow-900">
            {dbaWarningMessage}
            {' '}
            <span className="font-bold">{items.map((item) => item.siteName).join(', ')}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
