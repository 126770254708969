import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  INSTANCE_LOG_QUERY,
} from '../../../api/queries/instances';
import config from '../../../config';
import { LoadingIndicator } from '../../../layout';

function InstanceLog() {
  const { instanceId } = useParams();

  const {
    data: logsData,
    loading: logsLoading,
  } = useQuery(INSTANCE_LOG_QUERY, {
    pollInterval: config.REFETCH_LOGS_EVERY,
    variables: { instance: instanceId },
  });

  if (logsLoading) {
    return (<LoadingIndicator className="flex justify-center items-center pt-4" />);
  }

  if (!logsLoading
    && logsData
    && logsData.instanceLog
    && logsData.instanceLog.response
    && !logsData.instanceLog.response.length) {
    return (
      <span className="flex justify-center text-lg text-slate-600">This Instance has no logs.</span>
    );
  }

  return (
    <div className="align-middle inline-block min-w-full border-b border-slate-200">
      <table className="divide-y overflow-x-auto overflow-y-auto">
        <tbody>
          {logsData && logsData.instanceLog && logsData.instanceLog.response && logsData.instanceLog.response.map((line: string, index: number) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}>
                <td className="px-6 py-4 text-sm text-slate-600 break-all">{line}</td>
              </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default InstanceLog;
