import { useMutation } from '@apollo/client';
import {
  IconPinned, IconPinnedOff,
} from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { InstanceType } from '../../__generated__/graphql';
import { UNPIN_INSTANCE_MUTATION } from '../../api/mutations/instances';
import {
  PAGINATED_INSTANCES_QUERY,
  PINNED_INSTANCES_QUERY,
} from '../../api/queries/instances';
import { useAppSelector } from '../../helpers/reduxHooks';
import { classNames, getInitials, getRAMColorClass } from '../../helpers/utils';

interface Props {
  item: InstanceType,
}

function PinnedInstanceCard(props: Props) {
  const { item } = props;
  const { tab } = useParams();
  const instancesQueryAttributesState = useAppSelector(state => state.instancesQueryAttributes);
  const [pinnedOffDisplayed, setPinnedOffDisplayed] = useState(false);

  const [
    unpinInstanceMutation,
    { }] = useMutation(UNPIN_INSTANCE_MUTATION, {
    refetchQueries: [
      { query: PINNED_INSTANCES_QUERY },
      {
        query: PAGINATED_INSTANCES_QUERY,
        variables: {
          page: instancesQueryAttributesState.page,
          serverDomain: instancesQueryAttributesState.serverDomain,
          name: instancesQueryAttributesState.name,
          category: tab && tab.toUpperCase(),
        },
      },
    ],
  });

  return (
    <>
      <Link to={`/dashboard/${tab}/${item.id}/`} className="flex">
        <div
          className={classNames(
            getRAMColorClass(Number(item.phpRamUsagePercent), Number(item.dbRamUsagePercent)),
            'cursor-pointer flex-shrink-0 flex items-center justify-center w-16 text-white text-3xl font-medium rounded-l-md',
          )}
        >
          {getInitials(item.siteName)}
        </div>
      </Link>
      <div className="flex-1 flex items-center justify-between border-t border-r border-b border-slate-200 bg-white rounded-r-md truncate">
        <Link to={`/dashboard/${tab}/${item.id}/`} className="cursor-pointer flex-1 px-4 py-2 text-sm truncate">
          <p className="text-slate-900 text-base">
            {item.siteName}
          </p>
          <p className="text-slate-500">
            {item.mainDomain}
          </p>
        </Link>
        <div
          role="button"
          tabIndex={0}
          onClick={() => unpinInstanceMutation({ variables: { instance: item.id } })}
          className="flex-shrink-0 pr-4"
        >
          <Tippy content="Unpin Instance">
            <button
              onMouseEnter={() => setPinnedOffDisplayed(true)}
              onMouseLeave={() => setPinnedOffDisplayed(false)}
              className="w-8 h-8 bg-white inline-flex items-center justify-center text-slate-400 rounded-full hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
            >
              {pinnedOffDisplayed
                ? (
                  <IconPinnedOff className="w-5 h-5" aria-hidden="true" />
                )
                : (
                  <IconPinned className="w-5 h-5" aria-hidden="true" />
                )}
            </button>
          </Tippy>
        </div>
      </div>
    </>
  );
}

export default PinnedInstanceCard;
