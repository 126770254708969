interface Props {
  id?: string,
  name: string,
  type?: string,
  autoComplete?: string,
  required?: boolean,
  label?: string,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  errorMessage: string,
  hasSuffix?: boolean,
  suffixValue?: string,
  disabled?: boolean,
  value: string | number,
  placeholder: string,
}

function Input(props: Props) {
  const {
    id, name, type, autoComplete, required, label, onChange, errorMessage,
    hasSuffix, suffixValue, value, disabled, placeholder,
  } = props;
  const hasError = !!errorMessage || false;
  let inputClasses = hasError
    ? 'appearance-none block w-full py-2 px-3 border border-red-300 text-red-900 placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-2 focus:border-red-500 rounded-md'
    : 'appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-slate-400 focus:border-2 focus:border-slate-500';
  inputClasses = disabled ? 'bg-slate-100 text-slate-600 w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm' : inputClasses;
  const input = (
    <>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          autoComplete={autoComplete}
          onChange={onChange}
          aria-invalid={hasError}
          aria-describedby={id}
          className={inputClasses}
          disabled={disabled || undefined}
          placeholder={placeholder || ''}
        />
        {hasSuffix && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-slate-500 sm:text-sm" id="price-currency">
              {suffixValue}
            </span>
          </div>
        )}
      </div>
      {hasError && (
        <p className="mt-2 text-sm text-red-600" id={id}>
          {errorMessage}
        </p>
      )}
    </>
  );

  if (label) {
    return (
      <>
        <label
          htmlFor={name}
          className={`block text-sm text-slate-700 ${required && 'font-bold'}`}
        >
          {label}
        </label>
        {input}
      </>
    );
  }
  return input;
}

export default Input;
