import { useQuery, useMutation } from '@apollo/client';
import { IconClock } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommitType, InstanceType } from '../../../../__generated__/graphql';
import { CONTAINER_GITIFY_EXTRACT_MUTATION } from '../../../../api/mutations/containers';
import { NPM_INSTALL_MUTATION } from '../../../../api/mutations/npm';
import { 
  VERSION_CONTROL_APPLY_REMOTE_CHANGES_MUTATION, 
  VERSION_CONTROL_RESET_LOCAL_CHANGES_MUTATION, 
  VERSION_CONTROL_STORE_LOCAL_CHANGES_MUTATION,
} from '../../../../api/mutations/versionControl';
import { ACTION_LOGS_QUERY } from '../../../../api/queries/actionLogs';
import { INSTANCE_QUERY } from '../../../../api/queries/instances';
import { LATEST_COMMITS_QUERY } from '../../../../api/queries/versionControl';
import { CONTAINER_TYPE, ACTION_EVENT } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { getLabelFromEnumValue } from '../../../../helpers/utils';
import { LoadingIndicator } from '../../../../layout';
import { setSuccessAlert, setInfoAlert, setErrorAlert } from '../../../../redux/alertSlice';
import { 
  APPLY_REMOTE_CHANGES_CONFIRMATION, 
  STORE_LOCAL_CHANGES_CONFIRMATION, 
  RESET_LOCAL_CHANGES_CONFIRMATION,
  GITIFY_EXTRACT_CONFIRMATION,
  NPM_INSTALL_CONFIRMATION,
} from '../../../../redux/constants';
import { openConfirmationModal, resetConfirmationModal } from '../../../../redux/dashboardSlice';
import ActionOptions from './ActionOptions';
import CommitCard from './CommitCard';

type ActionOptionType = typeof APPLY_REMOTE_CHANGES_CONFIRMATION 
  | typeof STORE_LOCAL_CHANGES_CONFIRMATION 
  | typeof RESET_LOCAL_CHANGES_CONFIRMATION 
  | typeof GITIFY_EXTRACT_CONFIRMATION
  | typeof NPM_INSTALL_CONFIRMATION;

export interface ActionOptionParams {
  option: ActionOptionType,
  title: string,
}

interface Props {
  item: InstanceType,
}

function VersionControl(props: Props) {
  const { item } = props;
  const { instanceId } = useParams();
  const [actionOption, setActionOption] = useState<ActionOptionType | null>(null);
  const confirmationModal = useAppSelector(state => state.dashboard.confirmationModal);
  const dispatch = useAppDispatch();

  const modxContainer = item.containers.find(
    (container) => container.containerType === CONTAINER_TYPE.MODX,
  );

  const versionControlRefetchQueries = [
    { query: INSTANCE_QUERY, variables: { instance: instanceId } },
    { query: ACTION_LOGS_QUERY, variables: { instance: instanceId } },
  ];

  const {
    data: commitsData, 
    loading: commitsLoading,
  } = useQuery(LATEST_COMMITS_QUERY, {
    variables: { instance: instanceId },
    skip: !instanceId,
  });

  const [applyRemoteChangesMutation, 
    { },
  ] = useMutation(VERSION_CONTROL_APPLY_REMOTE_CHANGES_MUTATION, {
    refetchQueries: versionControlRefetchQueries,
  });

  const [storeLocalChangesMutation, 
    { },
  ] = useMutation(VERSION_CONTROL_STORE_LOCAL_CHANGES_MUTATION, {
    refetchQueries: versionControlRefetchQueries,
  });

  const [resetLocalChangesMutation, 
    { },
  ] = useMutation(VERSION_CONTROL_RESET_LOCAL_CHANGES_MUTATION, {
    refetchQueries: versionControlRefetchQueries,
  });

  const [gitifyExtractMutation,
    { },
  ] = useMutation(CONTAINER_GITIFY_EXTRACT_MUTATION, {
    refetchQueries: versionControlRefetchQueries,
  });

  const [npmInstallMutation, 
    { },
  ] = useMutation(NPM_INSTALL_MUTATION, {
    refetchQueries: versionControlRefetchQueries,
  });

  const actionOptionHandler = (optionParams: ActionOptionParams) => {
    setActionOption(optionParams.option);
    dispatch(openConfirmationModal({
      title: optionParams.title,
    }));
  };

  useEffect(() => {
    switch (actionOption) {
      case APPLY_REMOTE_CHANGES_CONFIRMATION:
        if (confirmationModal.actionApproved) {
          applyRemoteChangesMutation({
            variables: {
              instance: item.id,
            },
          });
          setActionOption(null);
          dispatch(resetConfirmationModal());
          dispatch(setInfoAlert({
            messages: ['Applying Remote Changes'],
            instanceId: instanceId as string,
          }));
        }
        break;

      case STORE_LOCAL_CHANGES_CONFIRMATION:
        if (confirmationModal.actionApproved) {
          storeLocalChangesMutation({
            variables: {
              instance: instanceId as string,
            },
          });
          setActionOption(null);
          dispatch(resetConfirmationModal());
          dispatch(setInfoAlert({
            messages: ['Sending Local Changes to the Repository'],
            instanceId: instanceId as string,
          }));
        }
        break;

      case RESET_LOCAL_CHANGES_CONFIRMATION:
        if (confirmationModal.actionApproved) {
          resetLocalChangesMutation({
            variables: {
              instance: instanceId as string,
            },
          });
          setActionOption(null);
          dispatch(resetConfirmationModal());
          dispatch(setInfoAlert({
            messages: ['Clearing out all files related changes since last commit'],
            instanceId: instanceId as string,
          }));
        }
        break;

      case GITIFY_EXTRACT_CONFIRMATION:
        if (confirmationModal.actionApproved) {
          gitifyExtractMutation({
            variables: {
              container: modxContainer?.id,
            },
          });
          setActionOption(null);
          dispatch(resetConfirmationModal());
          dispatch(setInfoAlert({
            messages: ['Processing Gitify Extraction'],
            instanceId: instanceId as string,
          }));
        }
        break;
        
      case NPM_INSTALL_CONFIRMATION:
        if (confirmationModal.actionApproved) {
          npmInstallMutation({
            variables: {
              instance: instanceId,
            },
          });
          setActionOption(null);
          dispatch(resetConfirmationModal());
          dispatch(setInfoAlert({
            messages: ['Installing packages in package.json'],
            instanceId: instanceId as string,
          }));
        }
        break;

      default:
        setActionOption(null);
        dispatch(resetConfirmationModal());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionOption, confirmationModal, dispatch, gitifyExtractMutation, 
    npmInstallMutation, resetLocalChangesMutation, storeLocalChangesMutation, applyRemoteChangesMutation]);

  useEffect(() => {
    if (item.latestActionLog
      && !item.latestActionLog.isDismissed) {
      if (item.latestActionLog.event === ACTION_EVENT.SUCCESS) {
        dispatch(setSuccessAlert({
          messages: [`${getLabelFromEnumValue(item.latestActionLog.actionType)} is successful`],
          instanceId: item.id,
        }));
      }
      if (item.latestActionLog.event === ACTION_EVENT.EXECUTION) {
        dispatch(setInfoAlert({
          messages: [`Processing ${getLabelFromEnumValue(item.latestActionLog.actionType)}`],
          instanceId: item.id,
        }));
      }
      if (item.latestActionLog.event === ACTION_EVENT.FAILURE) {
        dispatch(setErrorAlert({
          messages: [`${getLabelFromEnumValue(item.latestActionLog.actionType)} failed to complete`],
          instanceId: item.id,
        }));
      }
    }
  }, [dispatch, item]);

  if (commitsLoading) {
    return (<LoadingIndicator className="flex justify-center items-center pt-4" />);
  }

  return (
    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0">
      {commitsData && commitsData.latestCommits && commitsData.latestCommits.response
        ? (
          <div>
            <div className="px-4 sm:space-y-0 sm:gap-4 sm:px-6 sm:py-4">
              <div className="flex items-center">
                <ol className="border-l-2 border-slate-500">
                  {commitsData.latestCommits.response.map((commit: CommitType) => (
                    <CommitCard key={commit.id} item={commit} />
                  ))}
                </ol>
                <div className="ml-auto mb-auto pt-3 flex cursor-pointer">
                  {item.latestActionLog && item.latestActionLog.event === ACTION_EVENT.EXECUTION
                    ? (
                      <Tippy content="Processing">
                        <div
                          role="button"
                          tabIndex={0}
                        >
                          <IconClock className="h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
                        </div>
                      </Tippy>
                    )
                    : (
                      <Tippy content="Actions">
                        <div>
                          <ActionOptions actionOptionHandler={actionOptionHandler} />
                        </div>
                      </Tippy>
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>This Instance has no commits yet.</div>
        )}
    </div>
  );
}

export default VersionControl;
