import { gql } from '@apollo/client';

export const DAILY_CHART_QUERY = gql`
query chartSingle($date: Date!){
  chart(
    dateType: SINGLE
    date: $date){
      response{
        keys
        data
      }
      errors{
        field
        messages
      }
    }
}
`;

export const RANGED_CHART_QUERY = gql`
query chartRange(
  $date: Date!
  $endDate: Date
  ){
  chart(
    dateType: RANGE
    date: $date,
    endDate: $endDate
    ){
      response{
        keys
        data
      }
      errors{
        field
        messages
      }
    }
}
`;

export const DAILY_MANAGERS_ACTIVITY_QUERY = gql`
query managersActivitySignle($date: Date!){
  managersActivity(
    dateType: SINGLE
    date: $date){
      response{
        id
        name
        data{
          id
          manager
          actions
        }
      }
      errors{
        field
        messages
      }
  }
}
`;

export const RANGED_MANAGERS_ACTIVITY_QUERY = gql`
query managersActivityRange(
  $date: Date!
  $endDate: Date
  ){
  managersActivity(
    dateType: RANGE
    date: $date,
    endDate: $endDate
    ){
      response{
        id
        name
        data{
          id
          manager
          actions
        }
      }
      errors{
        field
        messages
      }
  }
}
`;
