import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { CreateInstanceForm } from '../forms';

interface Props {
  overlayState: boolean,
  setOverlayState: (value: boolean) => void,
}

function CreateInstance(props: Props) {
  const { overlayState, setOverlayState } = props;

  return (
    <Transition.Root show={overlayState} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOverlayState}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-white/10" />

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-100"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-100"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="shadow-2xl w-screen max-w-md">
                <CreateInstanceForm setOverlayState={setOverlayState} />
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CreateInstance;
