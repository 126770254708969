import { useMutation } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import {
  IconSelector, IconCheck, IconPlayerPause, IconAlertTriangle, IconFlame,
  IconHome2, IconActivity, IconUsers,
} from '@tabler/icons-react';
import { Fragment, useEffect } from 'react';
import {
  Link, useNavigate, useParams, useLocation,
} from 'react-router-dom';
import { InstancesQuantityType, UserType } from '../../__generated__/graphql';
import { REVOKE_REFRESH_TOKEN_MUTATION } from '../../api/mutations/users';
import { clearAuthCookies, getRefreshToken } from '../../helpers/auth';
import { classNames, getInitials } from '../../helpers/utils';
import { ServerSelector, SearchInput } from './dashboardSidebar/index';

interface Props {
  user: UserType,
  instancesQuantity: InstancesQuantityType,
}

function DashboardSidebar(props: Props) {
  const { user, instancesQuantity } = props;
  const location = useLocation();
  const { tab } = useParams();
  const navigate = useNavigate();
  const hasSidebarAccess = user.hasManagersActivityAccess || user.hasModxUsersAccess;

  const [revokeRefreshToken,
    { data: revokeRefreshTokenData },
  ] = useMutation(REVOKE_REFRESH_TOKEN_MUTATION);

  const handleLogout = () => {
    revokeRefreshToken({ variables: { refreshToken: getRefreshToken() } });
  };

  const appNavigation = [
    {
      name: 'Dashboard', to: '/dashboard/active', icon: IconHome2, isCurrent: location.pathname.includes('dashboard'),
    },
    {
      name: 'Managers Activity', to: '/managers-activity', icon: IconActivity, isCurrent: location.pathname.includes('managers-activity'),
    },
    {
      name: 'Managers Management', to: '/managers-management', icon: IconUsers, isCurrent: location.pathname.includes('managers-management'),
    },
  ];

  const instancesNavigation = [
    {
      name: `Active (${instancesQuantity ? instancesQuantity.runningQuantity : ''})`, to: 'active', icon: IconCheck,
    },
    {
      name: `Inactive (${instancesQuantity ? instancesQuantity.exitedQuantity : ''})`, to: 'exited', icon: IconPlayerPause,
    },
    {
      name: `Failed (${instancesQuantity ? instancesQuantity.errorQuantity : ''})`, to: 'error', icon: IconAlertTriangle,
    },
    {
      name: `High RAM Usage (${instancesQuantity ? instancesQuantity.highRamUsageQuantity : ''})`, to: 'high', icon: IconFlame,
    },
  ];

  useEffect(() => {
    if (revokeRefreshTokenData) {
      clearAuthCookies();
      navigate('/authentication/login/');
    }
  }, [revokeRefreshTokenData, navigate]);

  return (
    <div className="hidden lg:flex lg:fixed lg:inset-y-0 lg:border-r lg:border-slate-200 lg:bg-slate-100">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      {hasSidebarAccess && (
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-20">
            <div className="flex-1 flex flex-col min-h-0 bg-slate-500">
              <div className="flex-1">
                <nav aria-label="Sidebar" className="py-6 flex flex-col items-center space-y-3">
                  {appNavigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      //@ts-ignore
                      className={classNames(item.isCurrent && 'bg-slate-600', 'flex items-center p-4 rounded-lg text-white hover:bg-slate-700')}
                    >
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                      <span className="sr-only">{item.name}</span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
      {location.pathname.includes('dashboard') && (
        <div className={classNames(hasSidebarAccess ? 'lg:w-[19rem]' : 'lg:w-[18rem]', 'h-0 flex-1 flex flex-col lg:pt-5')}>
          {/* User account dropdown */}
          <Menu as="div" className="px-3 relative inline-block text-left z-10">
            <div>
              <Menu.Button className="group w-full bg-slate-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-slate-700 hover:bg-slate-200 ">
                <span className="flex w-full justify-between items-center">
                  <span className="flex min-w-0 items-center justify-between space-x-3">
                    <span className="inline-flex items-center justify-center h-14 w-14 rounded-lg bg-slate-500">
                      <span className="text-lg font-medium leading-none text-white">{getInitials(user.username as string)}</span>
                    </span>
                    <span className="flex-1 flex flex-col min-w-0">
                      <span className="text-slate-900 text-sm font-medium truncate">{user.username}</span>
                      <span className="text-slate-500 text-sm truncate">{user.email}</span>
                    </span>
                  </span>
                  <IconSelector
                    className="flex-shrink-0 h-5 w-5 text-slate-400 group-hover:text-slate-500"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-slate-200 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => handleLogout()}
                        className={classNames(
                          active ? 'bg-slate-100 text-slate-900' : 'text-slate-700',
                          'block px-4 py-2 text-sm cursor-pointer',
                        )}
                      >
                        Logout
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <div className="px-3 mt-5">
            <ServerSelector />
          </div>
          {/* Sidebar Search */}
          <div className="px-3 mt-2">
            <SearchInput />
          </div>
          {/* Navigation */}
          <nav className="px-3 mt-6">
            <div className="space-y-1">
              {instancesNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={`/dashboard/${item.to}/`}
                  className={classNames(
                    (tab === item.to) ? 'bg-slate-200 text-slate-800 font-bold' : 'text-slate-700 hover:text-slate-900 hover:bg-slate-50',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  )}
                  aria-current={tab === item.to ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      (tab === item.to) ? 'text-slate-800' : 'text-slate-400 group-hover:text-slate-500',
                      'mr-3 flex-shrink-0 h-6 w-6',
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </div>
          </nav>
        </div>
      )}
    </div>
  );
}

export default DashboardSidebar;
