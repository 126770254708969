import { ManagerActivityRecordType } from '../../__generated__/graphql';
import ActivityLogRecord from './ActivityLogRecord';

interface Props {
  items: ManagerActivityRecordType[],
}

function ActivityLog(props: Props) {
  const { items } = props;
  return (
    <ul className="mt-2 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-8 pb-2 border-b">
      {items.map((item) => (
        <li key={item.id} className="col-span-1 flex shadow-sm rounded-md">
          <ActivityLogRecord item={item} />
        </li>
      ))}
    </ul>
  );
}

export default ActivityLog;
