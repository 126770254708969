import { gql } from '@apollo/client';

export const USER_QUERY = gql`
  query user{
    user{
      id
      email
      isSuperuser
      hasManagersActivityAccess
      hasModxUsersAccess
      username
    }
  }
`;
