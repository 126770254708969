import { useQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import * as yup from 'yup';
import { AdditionalDomainType } from '../../../__generated__/graphql';
import { INSTANCE_UPDATE_MUTATION } from '../../../api/mutations/instances';
import {
  INSTANCE_QUERY,
} from '../../../api/queries/instances';
import { useAppDispatch } from '../../../helpers/reduxHooks';
import {
  updateSelectTheme,
} from '../../../helpers/utils';
import { Input } from '../../../layout/fields';
import { setSuccessAlert, setInfoAlert } from '../../../redux/alertSlice';
import { closeUpdateInstance } from '../../../redux/instancesSlice';

/**
 * Stop enter submitting the form.
 * @param keyEvent Event triggered when the user presses a key.
 */
function onKeyDown(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
  if ((keyEvent.code || keyEvent.key) === 'Enter') {
    keyEvent.preventDefault();
  }
}

interface FormikInitialStateType {
  mainDomain: string,
  siteName: string,
  aliasDomains: SelectOption[],
  redirectionDomains: SelectOption[],
}

function UpdateInstanceForm() {
  const { instanceId } = useParams();
  const dispatch = useAppDispatch();

  const
    {
      data: InstanceData,
    } = useQuery(
      INSTANCE_QUERY,
      {
        variables: { instance: instanceId },
        skip: instanceId === undefined,
      },
    );

  const instance = (InstanceData
    && InstanceData.instance
    && InstanceData.instance.response) ? InstanceData.instance.response : null;

  const [
    updateInstanceMutation,
    {
      data: updateInstanceData,
      loading: updateIntanceLoading,
    },
  ] = useMutation(
    INSTANCE_UPDATE_MUTATION,
    {
      refetchQueries: [{ query: INSTANCE_QUERY, variables: { instance: instanceId } }],
    },
  );

  const schema = yup.object({
    mainDomain: yup.string(),
    siteName: yup.string(),
    aliasDomains: yup.array(),
    redirectionDomains: yup.array(),
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    values: formikValues,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      mainDomain: instance && instance.mainDomain,
      siteName: instance && instance.siteName,
      aliasDomains: (instance && instance.aliasDomains.map(
        (item: AdditionalDomainType) => ({ label: item.name, value: item.id }),
      )) || [],
      redirectionDomains: (instance && instance.redirectDomains.map(
        (item: AdditionalDomainType) => ({ label: item.name, value: item.id }),
      )) || [],
    } as FormikInitialStateType,
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        instance: instanceId,
        mainDomain: values.mainDomain,
        siteName: values.siteName,
        aliasDomainsList: values.aliasDomains.map((item) => item.label),
        redirectionDomainsList: values.redirectionDomains.map((item) => item.label),
      };
      updateInstanceMutation({
        variables,
      });
    },
  });

  useEffect(() => {
    if (updateIntanceLoading) {
      dispatch(setInfoAlert({ 
        messages: [`Updating ${instance.siteName}.`], 
        instanceId: instanceId as string,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIntanceLoading]);

  useEffect(() => {
    if (
      updateInstanceData
      && updateInstanceData.instanceUpdate
      && updateInstanceData.instanceUpdate.response
    ) {
      dispatch(closeUpdateInstance());
      dispatch(setSuccessAlert({
        messages: [`${instance.siteName} has been updated.`],
        instanceId: instanceId as string,
      }),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInstanceData, dispatch]);

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={onKeyDown}
      className="flex h-full flex-col divide-y divide-slate-200"
    >
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-slate-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              <div>
                <label className="block text-sm font-bold text-slate-900">
                  Site Name
                  {' '}
                  <span className="mr-1 text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <Input
                    placeholder="Example: Zeller & Co"
                    name="siteName"
                    value={formikValues.siteName}
                    //@ts-ignore
                    errorMessage={touched.siteName && formikErrors.siteName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <label className="flex text-sm font-bold text-slate-900">
                  Main Domain
                  {' '}
                  <span className="mr-1 text-red-500">*</span>
                  {/* 'SERVER' AGRUMENT DOES NOT EXIST IN FORMIK VALUES AND IS NEVER DECLARED THERE */}
                  {/* {formikValues.server && (
                    <InfoTooltip
                      text={`Main Domain without a subdomain will automatically be extended with "${formikValues.server.label}" suffix`}
                    />
                  )} */}
                </label>
                <div className="mt-1">
                  <Input
                    placeholder="Example: zeller03.de"
                    name="mainDomain"
                    value={formikValues.mainDomain}
                    //@ts-ignore
                    errorMessage={touched.mainDomain && formikErrors.mainDomain}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-900">
                  Alias Domains
                </label>
                <div className="mt-1">
                  <CreatableSelect
                    isMulti
                    isClearable
                    value={formikValues.aliasDomains}
                    className="mt-1"
                    placeholder='Type in domain and press "Tab"'
                    onChange={(e) => (e
                      ? setFieldValue('aliasDomains', e)
                      : setFieldValue('aliasDomains', []))}
                    theme={(theme) => updateSelectTheme(theme)}
                    components={
                      {
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Menu: () => null,
                      }
                    }
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-900">
                  Redirection Domains
                </label>
                <div className="mt-1">
                  <CreatableSelect
                    isMulti
                    isClearable
                    value={formikValues.redirectionDomains}
                    className="mt-1"
                    placeholder='Type in domain and press "Tab"'
                    onChange={(e) => (e
                      ? setFieldValue('redirectionDomains', e)
                      : setFieldValue('redirectionDomains', []))}
                    theme={(theme) => updateSelectTheme(theme)}
                    components={
                      {
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Menu: () => null,
                      }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md border border-slate-300 bg-white py-2 px-4 text-sm font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
          onClick={() => dispatch(closeUpdateInstance())}
        >
          Back
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
        >
          Update
        </button>
      </div>
    </form>
  );
}

export default UpdateInstanceForm;
