import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ActionLogType, FontType } from '../../../__generated__/graphql';
import {
  AVAILABLE_FONTS_QUERY,
  CURRENT_FONTS_QUERY,
} from '../../../api/queries/fonts';
import { useAppSelector } from '../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../layout';
import { SetGoogleFontForm } from '../forms';
import AddFontCard from './googleFonts/AddFontCard';
import ApplyChanges from './googleFonts/ApplyChanges';
import FontCard from './googleFonts/FontCard';
import FontNote from './googleFonts/FontNote';
import NoFontCard from './googleFonts/NoFontCard';

function pendingChanges(storedFonts: FontType[], currentFonts: FontType[]) {
  if (storedFonts.length !== currentFonts.length) {
    return true;
  }
  const diffItems = currentFonts.map((item) => {
    const oldFont = storedFonts.find((oldItem) => oldItem.id === item.id);
    if (oldFont) {
      if (oldFont.family !== item.family
        || oldFont.variant !== item.variant) {
        return true;
      }
      return false;
    }
    return true;
  });

  if (diffItems.find((item) => item === true)) {
    return true;
  }
  return false;
}

export type AvailableFontFiles = {
  'string': string;
};

export interface AvailableFont {
  category: string,
  family: string,
  files: AvailableFontFiles,
  kind: string,
  lastModified: string,
  menu: string,
  subsets: string[],
  variants: string[],
  version: string,
}

interface Props {
  itemLatestActionLog: ActionLogType,
}

export default function SetGoogleFont( props: Props ) {
  const { itemLatestActionLog } = props;
  const [selectedFont, setSelectedFont] = useState<FontType | null>(null);
  const [storedFonts, setStoredFonts] = useState<FontType[]>([]);
  const [currentFonts, setCurrentFonts] = useState<FontType[]>([]);
  const [showForm, setShowForm] = useState(false);
  const { instanceId } = useParams();
  const fontsState = useAppSelector(state => state.fonts);

  const
    {
      data: fontsData,
      loading: fontsLoading,
    } = useQuery(AVAILABLE_FONTS_QUERY);

  const
    {
      data: currentFontsData,
    } = useQuery(CURRENT_FONTS_QUERY, { 
      variables: { instance: instanceId }, 
      fetchPolicy: 'network-only',
    });

  const fonts: AvailableFont[] | null = (fontsData && fontsData.availableFonts)
    ? fontsData.availableFonts.response.items
    : null;

  const handleFontSet = (inputFont: FontType) => {
    const foundFont = currentFonts.find(item => item.id === inputFont.id);
    if (foundFont) {
      const updatedFont = { ...foundFont, ...inputFont };
      const updatedFonts = currentFonts.map((item) => {
        if (item.id === updatedFont.id) {
          return updatedFont;
        }
        return item;
      });
      setCurrentFonts(updatedFonts);
    } else {
      const newFont = { ...inputFont };
      if (!currentFonts.length) {
        newFont.isDefault = true;
      }
      setCurrentFonts([...currentFonts, newFont]);
    }
    setSelectedFont(null);
    setShowForm(false);
  };

  const handleCardClick = (fontId?: string) => {
    if (selectedFont && selectedFont.id === fontId) {
      setSelectedFont(null);
      setShowForm(false);
    } else if (!fontId) {
      if (!selectedFont) {
        setShowForm(!showForm);
      }
      setSelectedFont(null);
    } else {
      const foundFont = currentFonts.find((item) => item.id === fontId);
      setSelectedFont(foundFont || null);
      setShowForm(true);
    }
  };

  const removeFont = (fontId: string) => {
    let clearedFonts = currentFonts.filter((item) => item.id !== fontId);
    if (clearedFonts.length && !clearedFonts[0].isDefault) {
      clearedFonts = clearedFonts.map((item, index) => {
        if (!item.isDefault && index === 0) {
          return { ...item, isDefault: true };
        }
        return { ...item, isDefault: false };
      });
    }
    setCurrentFonts(clearedFonts);
    setSelectedFont(null);
  };

  useEffect(() => {
    if (currentFontsData
      && currentFontsData.currentFonts
      && currentFontsData.currentFonts.response) {
      setStoredFonts(currentFontsData.currentFonts.response);
      setCurrentFonts(currentFontsData.currentFonts.response);
    }
  }, [currentFontsData]);

  if (fontsState.isLoading || fontsLoading) {
    return (<LoadingIndicator className="flex justify-center items-center pt-4" />);
  }
  return (
    <div className="px-4 pt-4 sm:space-y-0 sm:gap-4 sm:px-6">
      <div className="grid grid-cols-2 gap-4 pb-4">
        {!currentFonts.length && (<NoFontCard />)}
        {fonts && currentFonts
          && currentFonts.map((item) => (
            <FontCard
              key={item.id}
              family={item.family}
              variant={item.variant}
              itemId={item.id}
              isDefault={item.isDefault || false}
              isSelected={selectedFont && item.id === selectedFont.id}
              handleCardClick={handleCardClick}
              removeFont={removeFont}
            />
          ))
        }
        {fonts && (
          <AddFontCard
            isSelected={!selectedFont && showForm}
            handleCardClick={handleCardClick}
          />
        )}
      </div>
      {selectedFont && <FontNote currentFont={selectedFont} />}
      {showForm && (
        <SetGoogleFontForm
          fonts={fonts}
          selectedFont={selectedFont}
          handleFontSet={handleFontSet}
        />
      )}
      {!selectedFont
        && !showForm && pendingChanges(storedFonts, currentFonts) && (
          <ApplyChanges currentFonts={currentFonts} itemLatestActionLog={itemLatestActionLog} />
      )}
    </div>
  );
}
