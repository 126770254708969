/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';

function Option({
  children,
  isSelected,
  innerProps,
}: any) {
  return (
    <div
      className={cx('react-select__option', {
        'react-select__option_selected': isSelected,
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      {children}
    </div>
  );
}

export default Option;
