import Tippy from '@tippyjs/react';
import { ContainerType } from '../../../../__generated__/graphql';
import { CONTAINER_TYPE, ENTITY_STATUS } from '../../../../constants';
import { classNames, getOptionsListFromEnumDict, getRAMColorClass } from '../../../../helpers/utils';

interface Props {
  currentContainer: string,
  setCurrentContainer: (value: string) => void,
  containers: ContainerType[],
}

export default function ContainerTabs(props: Props) {
  const { currentContainer, setCurrentContainer, containers } = props;
  const containerOptions = getOptionsListFromEnumDict(CONTAINER_TYPE);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          //@ts-ignore
          onChange={(e) => setCurrentContainer(e.value)}
          className="block w-full focus:ring-orange-500 focus:border-orange-500 border-slate-300 rounded-md"
          defaultValue={currentContainer}
        >
          {containerOptions.map((tab) => (
            <option key={tab.value}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-slate-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {containerOptions.map((tab) => (
              <div
                role="button"
                tabIndex={0}
                key={tab.value}
                className={classNames(
                  currentContainer === tab.value
                    ? 'border-slate-600 text-slate-800'
                    : 'border-transparent text-slate-500 hover:text-slate-700 hover:border-slate-300',
                  'w-1/4 py-3 px-1 inline-flex items-center  justify-center border-b-2 font-medium text-sm',
                )}
                onClick={() => setCurrentContainer(tab.value)}
                aria-current={currentContainer === tab.value ? 'page' : undefined}
              >
                {containers?.find(
                  (item) => item.containerType === tab.value,
                )?.status === ENTITY_STATUS.RUNNING && (
                    <Tippy content={`RAM Usage: ${containers?.find((item) => item.containerType === tab.value)?.ramUsage}MB`}>
                      <div
                        className={classNames(getRAMColorClass(containers
                          .find((item) => item.containerType === tab.value)?.ramUsagePercent as number),
                        'mr-1 flex-shrink-0 w-1.5 h-4 rounded',
                        )}
                        aria-hidden="true"
                      />
                    </Tippy>
                )}
                {tab.label}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
