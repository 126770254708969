import { useRef, useState, useEffect } from 'react';
import { Calendar } from 'react-calendar';
import '../../../assets/css/Calendar.css';

interface Props {
  date: Date | Date[],
  setDate: (value: Date) => void,
  setEndDate: (value: Date) => void,
  className: string,
  hideCalendar: () => void,
}

function DatePicker(props: Props) {
  const {
    date, setDate, setEndDate, className, hideCalendar,
  } = props;
  const [clickedOutside, setClickedOutside] = useState(false);

  const myRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: Date) => {
    //@ts-ignore
    const [startDate, endDate] = e;
    setDate(startDate);
    setEndDate(endDate);
    hideCalendar();
  };

  const handleWeekSelect = (e: Date) => {
    const endDate = new Date(e);
    endDate.setDate(endDate.getDate() + 6);
    setDate(e);
    setEndDate(endDate);
    hideCalendar();
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (!myRef?.current?.contains(e.target as Node)) {
      setClickedOutside(true);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  useEffect(() => {
    if (clickedOutside) {
      hideCalendar();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedOutside]);

  return (
    <div className={className} ref={myRef} onClick={handleClickInside} role="button" tabIndex={0}>
      <Calendar
        selectRange
        showWeekNumbers
        onClickWeekNumber={(innerDate: Date) => handleWeekSelect(innerDate)}
        onChange={handleChange}
        value={date}
      />
    </div>
  );
}

export default DatePicker;
