interface Props {
  className?: string,
}

function LoadingIndicator(props: Props) {
  const { className } = props;
  const circleCommonClasses = 'h-2.5 w-2.5 bg-slate-500 rounded-full';

  return (
    <div className={`${className || 'flex'}`}>
      <div className={`${circleCommonClasses} mr-1 animate-bounce`} />
      <div
        className={`${circleCommonClasses} mr-1 animate-bounce200`}
      />
      <div className={`${circleCommonClasses} animate-bounce400`} />
    </div>
  );
}

export default LoadingIndicator;
