import { gql } from '@apollo/client';

export const INSTANCES_QUANTITY_QUERY = gql`
query instancesQuantity($name: String, $serverDomain: String){
  instancesQuantity(
    name: $name
    serverDomain: $serverDomain){
      response{
        runningQuantity
      exitedQuantity
      errorQuantity
      highRamUsageQuantity
      }
      errors{
        field
        messages
      }
  }
}
`;

export const INSTANCE_QUERY = gql`
  query instance(
    $instance: ID!,
    ) {
    instance(
      instance: $instance
    ) {
      response{
        id
        mainDomain
        siteName
        projectName
        createdAt
        responseCode
        isHttps
        isIdle
        status
        phpRamUsage
        dbRamUsage
        highlights
        dbaEnabled
        cronEnabled
        modxVersion
        unviewedActionLogsQuantity
        aliasDomains{
          id
          name
        }
        redirectDomains{
          id
          name
        }
        compatibility{
          id
          googleFont
          git
        }
        flavor{
          id
          name
          isActive
        }
        server{
          id
          domain
          serverLabel
        }
        containers{
          id
          name
          status
          ramUsage
          ramUsagePercent
          ramUsagePercentLabel
          containerType
          cronEnabled
        }
        latestActionLog{
          id
          actionType
          message
          event
          isDismissed
        }
        notesQuantity
      }
      errors{
        field
        messages
      }
    }
  }
`;

export const PAGINATED_INSTANCES_QUERY = gql`
  query paginatedInstances(
    $category: InstanceCategory!,
    $name: String,
    $serverDomain: String,
    $page: Int,
    ) {
      paginatedInstances(
      category: $category
      name: $name
      serverDomain: $serverDomain
      page: $page
    ) {
      response{
        page
        pages
        hasNext
        hasPrev
        elidedPageRange
        totalObjectsQuantity
        objects{
          id
          mainDomain
          siteName
          projectName
          responseCode
          isHttps
          isIdle
          isPinned
          status
          phpRamUsage
          phpRamUsagePercent
          phpRamUsagePercentLabel
          dbRamUsage
          dbRamUsagePercent
          dbRamUsagePercentLabel
          highlights
          dbaEnabled
          server{
            id
            serverLabel
          }
        }
      }
      errors{
        field
        messages
      }
    }
  }
`;

export const PINNED_INSTANCES_QUERY = gql`
  query pinnedInstances{
      pinnedInstances{
        response{
          id
          mainDomain
          siteName
          phpRamUsagePercent
          dbRamUsagePercent
        }
        errors{
          field
          messages
        }
    }
  }
`;

export const DBA_ENABLED_INSTANCES_QUERY = gql`
  query dbaEnabledInstances{
    dbaEnabledInstances{
      response{
        id
        siteName
      }
      errors{
        field
        messages
      }
    }
  }
`;

export const INSTANCE_LOG_QUERY = gql`
 query instanceLog($instance: ID!) {
  instanceLog(
    instance: $instance
  ){
    response
    errors{
      field
      messages
    }
  }
}
`;

export const INSTANCE_ENV_CONFIG_QUERY = gql`
 query envConfiguration($instance: ID!) {
  envConfiguration(
    instance: $instance
  ){
    response
    errors{
      field
      messages
    }
  }
}
`;

export const PHP_VERSION_QUERY = gql`
 query phpVersion($instance: ID!) {
  phpVersion(
    instance: $instance
  ){
    response
    errors{
      field
      messages
    }
  }
}
`;

export const DB_VERSION_QUERY = gql`
 query dbVersion($instance: ID!) {
  dbVersion(
    instance: $instance
  ){
    response
    errors{
      field
      messages
    }
  }
}
`;

export const UNPAGINATED_RUNNING_INSTANCES_QUERY = gql`
  query unpaginatedRunningInstances{
      unpaginatedRunningInstances{
        response{
          id
          siteName
        }
        errors{
          field
          messages
        }
    }
  }
`;
