import { useMutation } from '@apollo/client';
import {
  IconShield, IconPin, IconExternalLink, IconChevronRight, IconDatabase,
} from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { Link, useParams } from 'react-router-dom';
import status from 'statuses';
import { InstanceType } from '../../__generated__/graphql';
import { PIN_INSTANCE_MUTATION } from '../../api/mutations/instances';
import {
  PINNED_INSTANCES_QUERY,
  PAGINATED_INSTANCES_QUERY,
} from '../../api/queries/instances';
import { HIGHLIGHT_TYPE, ENTITY_STATUS } from '../../constants';
import { useAppSelector } from '../../helpers/reduxHooks';
import { classNames, getHTTPResponseDotColorClass, getRAMColorClass } from '../../helpers/utils';

interface Props {
  item: InstanceType,
}

function InstancesTableItem(props: Props) {
  const { item } = props;
  const { tab } = useParams();
  const instancesQueryAttributesState = useAppSelector(state => state.instancesQueryAttributes);

  const [
    pinInstanceMutation,
    { },
  ] = useMutation(PIN_INSTANCE_MUTATION, {
    refetchQueries: [
      { query: PINNED_INSTANCES_QUERY },
      {
        query: PAGINATED_INSTANCES_QUERY,
        variables: {
          page: instancesQueryAttributesState.page,
          serverDomain: instancesQueryAttributesState.serverDomain,
          name: instancesQueryAttributesState.name,
          category: tab && tab.toUpperCase(),
        },
      },
    ],
  });

  return (
    <tr key={item.id} className="hover:bg-slate-50">
      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium">
        <div className="flex items-center space-x-3 lg:pl-2 cursor-pointer">
          {item.responseCode && (
            <Tippy content={`HTTP Response: ${item.responseCode} (${status(item.responseCode)}); Secure: ${item.isHttps ? 'Yes' : 'No'}`}>
              <div
                className={classNames(getHTTPResponseDotColorClass(item, true), 'mr-2 flex-shrink-0 w-3.5 h-3.5 rounded-full')}
                aria-hidden="true"
              />
            </Tippy>
          )}
          <Link to={`/dashboard/${tab}/${item.id}/`} className="truncate hover:text-slate-600">
            <span>
              {item.siteName}
            </span>
          </Link>
        </div>
      </td>
      <td className="hidden sm:table-cell px-6 py-3 text-sm font-medium">
        <Link to={`/dashboard/${tab}/${item.id}/`}>
          {item.mainDomain}
        </Link>
      </td>
      <td className="hidden md:table-cell px-6 py-3 text-sm font-medium">
        {item && item.highlights && !!item.highlights.length && 
          item.highlights.map((highlight) => (
            <span key={highlight} className={classNames([HIGHLIGHT_TYPE.CRON, HIGHLIGHT_TYPE.DBA].includes(highlight as string) ? 'bg-orange-500' : 'bg-slate-500', 'inline-flex items-center px-2 py-0.5 mr-2 my-0.5 rounded text-xs font-medium text-white')}>
              {highlight}
            </span>
          ))}
      </td>
      <td className="hidden lg:table-cell px-6 py-3 whitespace-nowrap text-sm">
        {item.status === ENTITY_STATUS.RUNNING && (
          <div className="flex items-center">
            <div
              className={classNames(getRAMColorClass(Number(item.phpRamUsagePercent)), 'mr-1 flex-shrink-0 w-1.5 h-8 rounded')}
              aria-hidden="true"
            />
            <div>
              <div className="text-sm">
                {item.phpRamUsagePercentLabel}
              </div>
              <div className="text-[0.6rem]">
                {item.phpRamUsage}
                {' '}
                MB
              </div>
            </div>
          </div>
        )}
      </td>
      <td className="hidden lg:table-cell px-6 py-3 whitespace-nowrap text-sm">
        {item.status === ENTITY_STATUS.RUNNING && (
          <div className="flex items-center">
            <div
              className={classNames(getRAMColorClass(Number(item.dbRamUsagePercent)), 'mr-1 flex-shrink-0 w-1.5 h-8 rounded')}
              aria-hidden="true"
            />
            <div>
              <div className="text-sm">
                {item.dbRamUsagePercentLabel}
              </div>
              <div className="text-[0.6rem]">
                {item.dbRamUsage}
                {' '}
                MB
              </div>
            </div>
          </div>
        )}
      </td>
      <td className="hidden xl:table-cell px-6 py-3 whitespace-nowrap text-sm">
        {item?.server?.serverLabel}
      </td>
      <td className="px-6 py-3 text-slate-400 flex space-x-1">
        <Tippy content="Go to Site">
          <a target="_blank" href={`http://${item.mainDomain}`} rel="noopener noreferrer" className="icon">
            <IconExternalLink className="h-5 w-5 hover:text-slate-600 cursor-pointer" aria-hidden="true" />
          </a>
        </Tippy>
        <Tippy content="Go to Manager Panel">
          <a target="_blank" href={`http://${item.mainDomain}/luxadmin/`} rel="noopener noreferrer" className="icon">
            <IconShield className="h-5 w-5 hover:text-slate-600 cursor-pointer" aria-hidden="true" />
          </a>
        </Tippy>
        {item.dbaEnabled && (
          <Tippy content="Go to Database Admin Panel">
            <a target="_blank" href={`http://dba.${item.projectName}/`} rel="noopener noreferrer" className="icon">
              <IconDatabase className="ml-1 h-5 w-5  text-slate-400 hover:text-slate-600 cursor-pointer" aria-hidden="true" />
            </a>
          </Tippy>
        )}
        {!item.isPinned && (
          <Tippy content="Pin Instance">
            <div
              tabIndex={0}
              role="button"
              onClick={() => pinInstanceMutation({ variables: { instance: item.id } })}
            >
              <IconPin className="h-5 w-5 hover:text-slate-600 cursor-pointer" aria-hidden="true" />
            </div>
          </Tippy>
        )}
        <Tippy content="Show details">
          <Link to={`/dashboard/${tab}/${item.id}/`}>
            <IconChevronRight
              className="ml-1 h-5 w-5 hover:text-slate-600 cursor-pointer"
              aria-hidden="true"
            />
          </Link>
        </Tippy>
      </td>
    </tr>
  );
}

export default InstancesTableItem;
