import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface InstancesQueryAttributesState {
  name: string,
  serverDomain?: string,
  page: number,
}

// Define the initial state using that type
const initialState: InstancesQueryAttributesState = {
  name: '',
  page: 1,
};

export const instancesQueryAttributesSlice = createSlice({
  name: 'instancesQueryAttributes',
  initialState: initialState,
  reducers: {
    setSearchName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setServerDomain: (state, action: PayloadAction<string | undefined>) => {
      state.serverDomain = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const { setSearchName, setServerDomain, setPage } = instancesQueryAttributesSlice.actions;

export default instancesQueryAttributesSlice.reducer;
