import { useState, useEffect } from 'react';
import Select from 'react-select';
import type { SingleValue } from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { FontType } from '../../../__generated__/graphql';
import MenuList from '../../../helpers/lib/react-select/MenuList';
import Option from '../../../helpers/lib/react-select/Option';
import { useAppSelector } from '../../../helpers/reduxHooks';
import {
  updateSelectTheme,
} from '../../../helpers/utils';
import FontPreview from '../instanceDetails/googleFonts/FontPreview';
import { AvailableFont, AvailableFontFiles } from '../instanceDetails/SetGoogleFont';

/**
 * Stop enter submitting the form.
 * @param keyEvent Event triggered when the user presses a key.
 */
function onKeyDown(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
  if (keyEvent.key === 'Enter') {
    keyEvent.preventDefault();
  }
}

interface FormFont {
  category: string;
  family: string;
  file: string;
  id?: string | undefined;
  isDefault?: boolean | undefined;
  variant: string;
  version: string;
}

interface Props {
  fonts: AvailableFont[] | null,
  selectedFont: FontType | null,
  handleFontSet: (inputFont: FontType) => void,
}

function SetGoogleFontForm(props: Props) {
  const {
    fonts, selectedFont, handleFontSet,
  } = props;
  const [formFont, setFormFont] = useState<FormFont | FontType | null>(selectedFont);
  const fontsState = useAppSelector(state => state.fonts);

  const handleFontChange = (e: SingleValue<SelectOption>) => {
    const dataToApply = e && fonts && fonts?.find((item) => item.family === e.value);
    if (dataToApply) {
      const VariantToApply = dataToApply.variants[0];

      setFormFont({
        ...formFont,
        category: dataToApply.category as string,
        family: dataToApply.family as string,
        file: dataToApply.files[VariantToApply as keyof AvailableFontFiles],
        variant: VariantToApply,
        version: dataToApply.version as string,
      });
    }
    
  };

  const handleVariantChange = (value: string) => {
    if (fonts && formFont) {
      const dataToApply = fonts.find((item) => item.family === formFont.family);
      if (dataToApply) {
        setFormFont({
          ...formFont,
          variant: value,
          file: dataToApply.files[value as keyof AvailableFontFiles],
        });
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleFontSet(formFont as FontType);
    setFormFont(null);
  };

  useEffect(() => {
    if (!selectedFont) {
      const firstFont = fonts && fonts[0];
      const firstVariant = firstFont && firstFont.variants[0];

      if (firstFont && firstVariant) {
        const newFont: FontType = {
          id: uuidv4(),
          category: firstFont.category,
          family: firstFont.family,
          file: firstFont.files[firstVariant as keyof AvailableFontFiles],
          variant: firstVariant,
          version: firstFont.version,
        };
        setFormFont(newFont);
      }
    } else {
      setFormFont(selectedFont);
    }
  }, [fonts, selectedFont]);
  if (!formFont) {
    return '';
  }
  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={onKeyDown}
      className="flex h-full flex-col divide-y divide-slate-200"
    >
      <div className="h-0 flex-1 overflow-y-hidden">
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-slate-200">
            <div className="space-y-6 pt-6 pb-5">
              <div>
                <label className="block text-sm font-bold text-slate-900">
                  Font Family
                </label>
                <div className="mt-1">
                  <Select
                    value={{
                      value: formFont.family,
                      label: formFont.family,
                    }}
                    name="font-family"
                    options={fonts?.map((item) => ({
                      value: item.family,
                      label: item.family,
                      ...item,
                    }))}
                    classNamePrefix="select"
                    onChange={(e) => handleFontChange(e)}
                    theme={(theme) => updateSelectTheme(theme)}
                    components={{ MenuList, Option }}
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-bold text-slate-900">
                  Font Variant
                </label>
                <div className="mt-1">
                  <Select
                    value={{
                      value: formFont.variant,
                      label: formFont.variant,
                    }}
                    name="font-variant"
                    options={fonts?.find(
                      (item) => item.family === formFont.family,
                    )?.variants.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    classNamePrefix="select"
                    onChange={(e) => {if (e) handleVariantChange(e.value);}}
                    theme={(theme) => updateSelectTheme(theme)}
                    menuPortalTarget={document.body}
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-bold text-slate-900">
                  Font Preview
                  {' '}
                </label>
                <div className="mt-1">
                  <FontPreview
                    font={formFont as FontType}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!fontsState.isLoading && (
        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="submit"
            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
          >
            Set
          </button>
        </div>
      )}
    </form>
  );
}

export default SetGoogleFontForm;
