import { IconSearch, IconX } from '@tabler/icons-react';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { setSearchName } from '../../../redux/instancesQueryAttributesSlice';

export default function SearchInput() {
  const dispatch = useAppDispatch();
  const searchedName = useAppSelector(state => state.instancesQueryAttributes.name);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    dispatch(setSearchName(name));
  };

  return (
    <>
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div
          className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          aria-hidden="true"
        >
          <IconSearch className="mr-3 h-4 w-4 text-slate-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="search"
          id="search"
          value={searchedName}
          className="focus:ring-slate-500 focus:border-slate-500 block w-full pl-9 sm:text-sm border-slate-300 rounded-md"
          placeholder="Search"
          onChange={handleSearchInput}
        />
        {!!searchedName && (
          <>
            <div
              className="absolute inset-y-0 right-0 pl-3 flex items-center cursor-pointer"
              aria-hidden="true"
            >
              <IconX 
                className="mr-3 h-4 w-4 text-slate-400 hover:text-slate-600" 
                aria-hidden="true"
                onClick={() => dispatch(setSearchName(''))} 
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
