import { gql } from '@apollo/client';

export const NOTES_QUERY = gql`
query notes($instance: ID!){
  notes(
    instance: $instance){
      errors{
        field
        messages
      }
      response{
        id
        createdAt
        updatedAt
        body
        author{
          id
          username
        }
      }
  }
}
`;
