import { Menu, Transition } from '@headlessui/react';
import {
  IconDotsVertical, IconTrash, IconEdit,
} from '@tabler/icons-react';
import { Fragment } from 'react';
import { useAppDispatch } from '../../../../helpers/reduxHooks';
import { openConfirmationModal } from '../../../../redux/dashboardSlice';
import { setRequestDelete, setRequestUpdate } from '../../../../redux/notesSlice';

export default function ActionOptions() {
  const dispatch = useAppDispatch();

  const handleDeleteClick = () => {
    dispatch(setRequestDelete(true));
    dispatch(openConfirmationModal({
      title: 'Confirm Note deletion',
      description: 'This action cannot be undone.',
    }));
  };
  
  return (
    <Menu as="div" className="relative inline-block text-left ml-auto">
      <div>
        <Menu.Button className="flex items-center text-slate-400 hover:text-slate-600">
          <span className="sr-only">Open options</span>
          <IconDotsVertical className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              <div
                role="button"
                tabIndex={0}
                className="text-gray-700 group flex items-center px-4 py-2 text-sm"
                onClick={() => dispatch(setRequestUpdate(true))}
              >
                <IconEdit className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                Edit
              </div>
            </Menu.Item>
            <Menu.Item>
              <div
                role="button"
                tabIndex={0}
                className="text-gray-700 group flex items-center px-4 py-2 text-sm"
                onClick={() => handleDeleteClick()}

              >
                <IconTrash className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                Delete
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
