export const ENTITY_STATUS = {
  NOT_DEPLOYED: 'NOT_DEPLOYED',
  DEPLOYING: 'DEPLOYING',
  RESTARTING: 'RESTARTING',
  REBUILDING: 'REBUILDING',
  UPDATING: 'UPDATING',
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
  EXITED: 'EXITED',
  ERROR: 'ERROR',
};

export const CONTAINER_TYPE = {
  MODX: 'MODX',
  DB: 'DB',
  REDIRECTOR: 'REDIRECTOR',
  DB_ADMIN_PANEL: 'DB_ADMIN_PANEL',
};

export const ENTITY_ACTION = {
  REBUILD: 'REBUILD',
  REFRESH: 'REFRESH',
  START: 'START',
  STOP: 'STOP',
  GITIFY_EXTRACT: 'GITIFY_EXTRACT',
  NORMALIZE_PERMISSIONS: 'NORMALIZE_PERMISSIONS',
  GULP_BUILD_DEVELOPMENT: 'GULP_BUILD_DEVELOPMENT',
  GULP_BUILD_PRODUCTION: 'GULP_BUILD_PRODUCTION',
  START_CRON: 'START_CRON',
  CANCEL_CRON: 'CANCEL_CRON',
};

export const RAM_USAGE_THRESHOLD = {
  GREEN: 0.5,
  YELLOW: 0.80,
};

export const SIGNATURE_EXPIRED_ERROR = 'Signature has expired';
export const REFRESH_TOKEN_EXPIRED_ERROR = 'Refresh token is expired';

export const ACTION_EVENT = {
  EXECUTION: 'EXECUTION',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export enum AlertType {
  INFO = 'INFO',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export const INSTANCE_TYPE = {
  ACTIVE: 'ACTIVE',
  EXITED: 'EXITED',
  ERROR: 'ERROR',
  HIGH: 'HIGH',
};

export const HIGHLIGHT_TYPE = {
  ALIAS: 'ALIAS',
  REDIRECT: 'REDIRECT',
  CRON: 'CRON',
  DBA: 'DBA',
  IDLE: 'IDLE',
  NOTES: 'NOTES',
};

export const VIEW_TAB = {
  OVERVIEW: 'Overview',
  NOTES: 'Notes',
  ACTION_LOG: 'Action Log',
  INSTANCE_LOG: 'Instance Log',
  INSTANCE_ENV_CONFIG: 'Environment Configuration',
  BACKUPS: 'Backups',
  FONTS: 'Fonts',
  VERSION_CONTROL: 'Version Control',
};

export const MODX_USERS_ACTIONS = {
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE',
  REMOVE: 'REMOVE',
};
