import moment from 'moment-timezone';
import type { CSSObjectWithLabel, ControlProps, GroupBase, Theme } from 'react-select';
import { FontType, InstanceType } from '../__generated__/graphql';
import { ENTITY_STATUS, RAM_USAGE_THRESHOLD } from '../constants';

const SYMBOLS = 'abcdefghijklmnopqrstuvwxyz0123456789';
const UPPERCASE_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function getInitials(name: string) {
  return name.match(/(^\S\S?|\b\S)?/g)?.join('').match(/(^\S|\S$)?/g)?.join('')
    .toUpperCase();
}

// Update css theme for react-select
export function updateSelectTheme(theme: Theme) {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#64748b',
      neutral50: '#94a3b8', // Placeholder color
      neutral40: '#94a3b8', // No-Options color
    },
  };
}

export const updateSelectStylesOnError = {
  control: (styles: CSSObjectWithLabel, state: ControlProps<unknown, boolean, GroupBase<unknown>>) => ({
    ...styles,
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(252, 75, 108, 0.25)' : 0,
    borderColor: '#f6aaab',
    '&:hover': {
      borderColor: '#ef4444',
    },
  }),
};

export function formatTimestamp(dateString: Date) {
  const date = moment(dateString);
  const format = 'YYYY-MM-DD HH:mm';
  // Treat London time as UTC-0
  const calcualted = moment.tz(date, 'Europe/London');
  return calcualted.tz(moment.tz.guess(true)).format(format);
}

export function getContainerCardStatusColor(containerStatus: string) {
  let result;
  switch (containerStatus) {
    case ENTITY_STATUS.DEPLOYING:
      result = 'bg-blue-500';
      break;
    case ENTITY_STATUS.EXITED:
    case ENTITY_STATUS.NOT_DEPLOYED:
      result = 'bg-slate-500';
      break;
    case ENTITY_STATUS.ERROR:
      result = 'bg-red-400';
      break;
    default:
      result = 'bg-emerald-500';
  }
  return result;
}

// Defines the color of RAM usage bars (in accordance with the maximum value)
// ...argum - allows a function to accept any number of arguments as an array
export function getRAMColorClass(...argum: number[]) {
  let result = 'bg-slate-500';
  const ramUsagePercent = Math.max(...argum);

  if (ramUsagePercent && ramUsagePercent < RAM_USAGE_THRESHOLD.GREEN) {
    result = 'bg-emerald-500';
  } else if (ramUsagePercent < RAM_USAGE_THRESHOLD.YELLOW) {
    result = 'bg-yellow-500';
  } else {
    result = 'bg-red-400';
  }
  return result;
}

export function getHTTPResponseDotColorClass(instance: InstanceType, isStrict: boolean) {
  let result;
  if (instance.responseCode === 200 && (!isStrict || instance.isHttps)) {
    result = 'bg-emerald-500';
  } else if (instance.responseCode === 200) {
    result = 'bg-yellow-500';
  } else {
    result = 'bg-slate-500';
  }
  return result;
}

export function getSecureStateColorClass(isHttps: boolean) {
  if (isHttps) {
    return 'bg-emerald-500';
  }
  return 'bg-yellow-500';
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function getLabelFromEnumValue(value: string) {
  const tmp = value.toLowerCase().replaceAll('_', ' ');
  return toTitleCase(tmp);
}

interface EnumDictionaryType {
  [key: string]: string,
}

export function getOptionsListFromEnumDict(enumDict: EnumDictionaryType, prettifyLabel = true) {
  // "EnumDict" means dictionary that is used as enumerator
  return Object.entries(enumDict).map(
    (item) => {
      const [key, value] = item;
      const label = prettifyLabel ? getLabelFromEnumValue(value) : value;
      return { value: key, label };
    },
  );
}

export function getDateLabel(date: Date) {
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  return `${year}-${month}-${day}`;
}

function prepareSafeSymbol(symbol: string) {
  if ((SYMBOLS + UPPERCASE_LETTERS).includes(symbol)) return symbol;
  return '-';
}

export function getFontVariantName(inputVariant: string) {
  const re = /(\d*)(\w*)/;
  const parsedVariant = re.exec(inputVariant);
  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [variant, pre, post] = parsedVariant;
  if (pre && post) {
    return `${pre}-${post}`;
  }
  return inputVariant;
}

export function getSafeName(font: FontType) {
  const safeFamily = [...font.family].map((symbol) => prepareSafeSymbol(symbol)).join('').toLowerCase();
  const fontVariant = getFontVariantName(font.variant);
  if (fontVariant === 'regular') {
    return safeFamily;
  }
  return `${safeFamily}-${fontVariant}`;
}

export function capitalize(inputString: string) {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}


export function getInstanceIdFromUrl(url: string) {
  const regex = /\/[a-z]+\/[a-z]+\/(\d+)[\/]?$/i;
  const match = url.match(regex);
  return match ? parseInt(match[1], 10) : null;
}