import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { InstanceType } from '../../__generated__/graphql';
import { ACTIVATE_MODX_USERS_MUTATION, DEACTIVATE_MODX_USERS_MUTATION, REMOVE_MODX_USERS_MUTATION } from '../../api/mutations/modxUsersManagement';
import { UNPAGINATED_RUNNING_INSTANCES_QUERY } from '../../api/queries/instances';
import { MODX_INSTANCE_USERS_QUERY } from '../../api/queries/modxUsersManagement';
import { MODX_USERS_ACTIONS } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../helpers/reduxHooks';
import { updateSelectTheme } from '../../helpers/utils';
import { LoadingIndicator } from '../../layout';
import { InfoAlert, ReduxAlert } from '../../layout/alerts';
import { discardAlert, setSuccessAlert } from '../../redux/alertSlice';
import { Activate, Deactivate, Remove } from './buttons';

function SingleView() {
  const [instance, setInstance] = useState<SelectOption | null>(null);
  const [availableUsernames, setAvailableUsernames] = useState([]);
  const [usernames, setUsernames] = useState<SelectOption[]>([]);
  const [action, setAction] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const alertsState = useAppSelector((state) => state.alert);
  const dispatch = useAppDispatch();

  const {
    data: instancesData,
  } = useQuery(UNPAGINATED_RUNNING_INSTANCES_QUERY);

  const [
    getUsers, {
      data: usersData,
      loading: getUsersLoading,
    }] = useLazyQuery(MODX_INSTANCE_USERS_QUERY);

  const [activateModxUsersMutation, 
    { 
      loading: activateUserLoading,
    },
  ] = useMutation(ACTIVATE_MODX_USERS_MUTATION);

  const [deactivateModxUsersMutation, 
    { 
      loading: deactivateUserLoading,
    },
  ] = useMutation(DEACTIVATE_MODX_USERS_MUTATION);

  const [removeModxUsersMutation, 
    {
      loading: removeUserLoading,
    },
  ] = useMutation(REMOVE_MODX_USERS_MUTATION, {
    refetchQueries: [
      { query: MODX_INSTANCE_USERS_QUERY, 
        variables: { instance: instance?.value },
      },
    ],
  });

  const mutationVariables = {
    instance: instance && instance.value,
    users: usernames.map((item) => item.value),
  };

  const instancesOptions = (instancesData
    && instancesData.unpaginatedRunningInstances
    && instancesData.unpaginatedRunningInstances.response)
    ? instancesData.unpaginatedRunningInstances.response.map(
      (item: InstanceType) => ({ value: item.id, label: item.siteName }),
    )
    : [];

  const handleActionClick = (inputAction: string) => {
    if (action === inputAction) {
      if (!isConfirmed) {
        setIsConfirmed(true);
      }
    } else {
      setAction(inputAction);
      setIsConfirmed(false);
    }
  };

  useEffect(() => {
    if (instance) {
      getUsers({ variables: { instance: instance.value } });
    } else {
      setAvailableUsernames([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  useEffect(() => {
    if (usersData && usersData.modxInstanceUsers) {
      const usernameOptions = usersData.modxInstanceUsers.response.map(
        (item: string) => ({ value: item, label: item }),
      );
      setAvailableUsernames(usernameOptions);
    }
  }, [usersData]);

  useEffect(() => {
    if (isConfirmed) {
      switch (action) {
        case MODX_USERS_ACTIONS.ACTIVATE:
          activateModxUsersMutation({
            variables: mutationVariables,
            onCompleted: () => {
              dispatch(setSuccessAlert({
                messages: ['Selected Users were Activated'],
              }));
            },
          });
          break;
        
        case MODX_USERS_ACTIONS.DEACTIVATE:
          deactivateModxUsersMutation({
            variables: mutationVariables,
            onCompleted: () => {
              dispatch(setSuccessAlert({
                messages: ['Selected Users were Deactivated'],
              }));
            },
          });
          break;

        case MODX_USERS_ACTIONS.REMOVE:
          removeModxUsersMutation({
            variables: mutationVariables,
            onCompleted: () => {
              dispatch(setSuccessAlert({
                messages: ['Selected Users were Removed'],
              }));
            },
          });
          break;

        default:
          break;
      }
      setUsernames([]);
      setAction('');
      setIsConfirmed(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed, action]);

  return (
    <div className="mx-auto max-w-7xl px-4">
      <div className="h-[42rem] mt-6 grid grid-cols-2 gap-0.5 lg:mt-8">
        <div>
          <div className="mb-6 ">
            <div className="block text-sm font-medium text-slate-900">
              Please select Instance first
            </div>
            <div className="mt-1">
              <Select
                isClearable
                className="mt-1"
                placeholder="Select Instance"
                options={instancesOptions}
                onChange={(e) => (e
                  ? setInstance(e as SelectOption)
                  : setInstance(null))}
                theme={(theme) => updateSelectTheme(theme)}
              />
            </div>
          </div>
          {getUsersLoading && <LoadingIndicator className="flex justify-center items-center pt-4" />}
          {!getUsersLoading && !!availableUsernames.length && (
            <div className="mb-6 ">
              <div className="block text-sm font-medium text-slate-900">
                Please chose users from options here
              </div>
              <div className="mt-1">
                <Select
                  value={usernames || []}
                  isMulti
                  isClearable
                  className="mt-1"
                  placeholder='Type in username and press "Enter"'
                  onChange={(e) => (e
                    ? setUsernames(e as SelectOption[])
                    : setUsernames([]))}
                  options={availableUsernames}
                  theme={(theme) => updateSelectTheme(theme)}
                />
              </div>
            </div>
          )}
          {(activateUserLoading || deactivateUserLoading || removeUserLoading) && (
            <InfoAlert message="Applying now. Please be patient. It takes few seconds per Instance." />
          )}
          {alertsState.alert
            && (
              <ReduxAlert
                className="rounded-md"
                key={alertsState.alert.id}
                alertType={alertsState.alert.alertType}
                messageList={alertsState.alert.messages}
                handleClose={() => { dispatch(discardAlert()); }}
              />
            )}
          {!!usernames.length && (
            <div>
              <div className="grid grid-cols-3 gap-0.5 lg:mt-8 text-slate-400">
                <Activate
                  handleClick={() => handleActionClick(MODX_USERS_ACTIONS.ACTIVATE)}
                  awaitsConfirmation={
                    action === MODX_USERS_ACTIONS.ACTIVATE && isConfirmed === false
                  }
                />
                <Deactivate
                  handleClick={() => handleActionClick(MODX_USERS_ACTIONS.DEACTIVATE)}
                  awaitsConfirmation={
                    action === MODX_USERS_ACTIONS.DEACTIVATE && isConfirmed === false
                  }
                />
                <Remove
                  handleClick={() => handleActionClick(MODX_USERS_ACTIONS.REMOVE)}
                  awaitsConfirmation={
                    action === MODX_USERS_ACTIONS.REMOVE && isConfirmed === false
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleView;
