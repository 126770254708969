import { IconInfoCircle } from '@tabler/icons-react';
import { FontType } from '../../../../__generated__/graphql';
import { getSafeName } from '../../../../helpers/utils';

interface Props {
  currentFont: FontType,
}

function FontNote(props: Props) {
  const { currentFont } = props;

  return (
    <div className="bg-blue-100 p-2 flex items-center space-x-2 rounded">
      <div>
        <IconInfoCircle className="w-7 h-7 text-blue-700" aria-hidden="true" />
      </div>
      <div className="text-sm">
        {currentFont.isDefault
          ? (<span>This font is used by default for pages rendered with Tailwind</span>)
          : (
            <>
              <div>You can apply this font to HTML pages rendered with Tailwind like:</div>
              <div className="font-semibold">{`<div class="${getSafeName(currentFont)}">Hello World</div>`}</div>
            </>
          )}

      </div>
    </div>
  );
}

export default FontNote;
