import { useQuery, useMutation } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import {
  IconX,
} from '@tabler/icons-react';
import { Fragment, useEffect, useState } from 'react';
import { DISMISS_ACTION_LOG_MUTATION } from '../../../api/mutations/actionLogs';
import {
  INSTANCE_QUERY,
} from '../../../api/queries/instances';
import config from '../../../config';
import { VIEW_TAB } from '../../../constants';
import { useAppSelector, useAppDispatch } from '../../../helpers/reduxHooks';
import { ReduxAlert } from '../../../layout/alerts';
import { discardAlert } from '../../../redux/alertSlice';
import {
  VerticalTabs, ActionLog, InstanceLog, Backups, EnvConfiguration,
} from '../instanceDetails';
import Notes from '../instanceDetails/notes/Notes';
import Overview from '../instanceDetails/overview/Overview';
import SetGoogleFont from '../instanceDetails/SetGoogleFont';
import VersionControl from '../instanceDetails/versionControl/VersionControl';

interface Props {
  instanceId: string,
  overlayState: boolean,
  closeInstanceDetails: () => void,
}

function InstanceCard(props: Props) {
  const {
    overlayState, closeInstanceDetails, instanceId,
  } = props;
  const [currentTab, setCurrentTab] = useState(VIEW_TAB.OVERVIEW);
  const alertsState = useAppSelector(state => state.alert);
  const dispatch = useAppDispatch();

  const
    {
      data: InstanceData,
    } = useQuery(
      INSTANCE_QUERY,
      {
        variables: { instance: instanceId },
        skip: !instanceId,
        pollInterval: config.REFETCH_INSTANCE_DETAILS_EVERY,
      },
    );

  const [
    dismissActionLogMutation,
    { }] = useMutation(DISMISS_ACTION_LOG_MUTATION, {
    refetchQueries: [{ query: INSTANCE_QUERY, variables: { instance: instanceId } }],
  });

  const item = (InstanceData && InstanceData.instance && InstanceData.instance.response)
    ? InstanceData.instance.response
    : null;

  const handleClose = (actionLogId: string) => {
    dismissActionLogMutation({ variables: { actionLog: actionLogId } });
    dispatch(discardAlert());
  };

  useEffect(() => {
    setCurrentTab(VIEW_TAB.OVERVIEW);
  }, [instanceId ]);

  return (
    <Transition.Root show={overlayState} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => closeInstanceDetails()}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-white/10" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-400"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-400"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="max-w-[100vh] w-screen flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-9 pt-2 plr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="text-slate-600 rounded-md"
                      onClick={() => closeInstanceDetails()}
                    >
                      <IconX className="h-8 w-8" />
                    </button>
                  </div>
                </Transition.Child>
                {item && (
                  <div className="border-r bg-slate-100">
                    <VerticalTabs
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      notesQuantity={item.notesQuantity}
                      unviewedActionLogsQuantity={item.unviewedActionLogsQuantity}
                      compatibility={item.compatibility}
                    />
                    <div
                      className="rotate-180 absolute bottom-5 left-3 text-3xl font-medium text-slate-400"
                      style={{ writingMode: 'vertical-rl' }}
                    >
                      <span>
                        {item.siteName}
                      </span>
                    </div>
                  </div>
                )}
                <div id="styled-scroll" className="grow flex h-full bg-white shadow-xl overflow-y-auto">
                  <div className="grow">
                    {item && (
                      <div>
                        <div>
                          {alertsState.alert
                            && alertsState.alert?.instanceId === instanceId
                            && (
                              <ReduxAlert
                                key={alertsState.alert.id}
                                alertType={alertsState.alert.alertType}
                                messageList={alertsState.alert.messages}
                                handleClose={() => { handleClose(item.latestActionLog.id);}}
                              />
                            )}
                        </div>
                        {currentTab === VIEW_TAB.OVERVIEW && (
                          <Overview item={item} />
                        )}
                        {currentTab === VIEW_TAB.NOTES && (
                          <Notes />
                        )}
                        {currentTab === VIEW_TAB.ACTION_LOG && (
                          <ActionLog />
                        )}
                        {currentTab === VIEW_TAB.INSTANCE_LOG && (
                          <InstanceLog />
                        )}
                        {currentTab === VIEW_TAB.INSTANCE_ENV_CONFIG && (
                          <EnvConfiguration />
                        )}
                        {currentTab === VIEW_TAB.BACKUPS && (
                          <Backups />
                        )}
                        {currentTab === VIEW_TAB.FONTS && (
                          <SetGoogleFont itemLatestActionLog={item.latestActionLog} />
                        )}
                        {currentTab === VIEW_TAB.VERSION_CONTROL && (
                          <VersionControl item={item} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default InstanceCard;
