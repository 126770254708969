import { IconTypographyOff } from '@tabler/icons-react';

function NoFontCard() {
  return (
    <div>
      <div className="flex rounded-lg">
        <div
          className="bg-slate-500 flex-shrink-0 flex items-center justify-center h-16 w-16 text-white text-3xl font-medium rounded-l-md"
        >
          <IconTypographyOff className="h-8 w-8" />
        </div>

        <div
          className="border-t border-r border-b flex-1 flex items-center justify-between  border-slate-200 bg-white rounded-r-md truncate"
        >
          <div
            className="flex-1 px-4 py-2 text-sm truncate max-w-xl"
          >
            <div className="text-base flex">
              <div>
                <span>No Font is set</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoFontCard;
