import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface ConfirmationModal {
  isOpen: boolean,
  title?: string,
  description?: string,
  actionApproved?: boolean,
  actionDeclined?: boolean,
}

interface ConfirmationModalPayload {
  title?: string,
  description?: string,
}


interface DashboardState {
  confirmationModal: ConfirmationModal,
}

// Define the initial state using that type
const initialState: DashboardState = {
  confirmationModal: {
    isOpen: false,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    openConfirmationModal: (state, action: PayloadAction<ConfirmationModalPayload>) => {
      state.confirmationModal = {
        ...state.confirmationModal,
        ...action.payload,
        isOpen: true,
      };
    },
    resetConfirmationModal: (state) => {
      state.confirmationModal = initialState.confirmationModal;
    },
    approveConfirmationModal: (state) => {
      state.confirmationModal.actionApproved = true;
      state.confirmationModal.isOpen = false;
    },
    declineConfirmationModal: (state) => {
      state.confirmationModal.actionDeclined = true;
      state.confirmationModal.isOpen = false;
    },
  },
});

export const {
  approveConfirmationModal, resetConfirmationModal, openConfirmationModal, declineConfirmationModal,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
