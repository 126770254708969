import { InstanceType } from '../../__generated__/graphql';
import InstancesTableItem from './InstancesTableItem';

interface Props {
  items: InstanceType[] | undefined,
}

function InstancesTable(props: Props) {
  const { items } = props;
  return (
    <div className="align-middle inline-block min-w-full border-b border-slate-200">
      <table className="min-w-full">
        <thead>
          <tr className="border-t border-slate-200">
            <th className="px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">
              <span className="lg:pl-2">Name</span>
            </th>
            <th className="hidden sm:table-cell px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">
              Main Domain
            </th>
            <th className="hidden md:table-cell px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">
              Highlights
            </th>
            <th className="hidden lg:table-cell px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">
              PHP RAM
            </th>
            <th className="hidden lg:table-cell px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">
              DB RAM
            </th>
            <th className="hidden xl:table-cell px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">
              Server
            </th>
            <th className="px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-800">
          {items?.map((item) => (
            <InstancesTableItem key={item.id} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default InstancesTable;
