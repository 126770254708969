import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  DAILY_CHART_QUERY,
  DAILY_MANAGERS_ACTIVITY_QUERY,
  RANGED_CHART_QUERY,
  RANGED_MANAGERS_ACTIVITY_QUERY,
} from '../api/queries/managersActivity';
import { USER_QUERY } from '../api/queries/users';
import { DashboardSidebar } from '../components/dashboard';
import {
  Chart, ActivitiesLog, DailyDateNav, RangedDateNav,
} from '../components/managersActivity';
import { clearAuthCookies } from '../helpers/auth';
import { getDateLabel } from '../helpers/utils';
import { Switch } from '../layout/fields';

interface QueryDatesType {
  date: string,
  endDate?: string,
}

const prevDate = new Date();
prevDate.setDate(prevDate.getDate() - 1);

export default function ManagersActivity() {
  const [isRange, setIsRange] = useState(false);
  const [date, setDate] = useState(prevDate);
  const [endDate, setEndDate] = useState(new Date());
  const { data: userData, error: userError } = useQuery(USER_QUERY);
  const chartQuery = isRange ? RANGED_CHART_QUERY : DAILY_CHART_QUERY;
  const activityQuery = isRange ? RANGED_MANAGERS_ACTIVITY_QUERY : DAILY_MANAGERS_ACTIVITY_QUERY;
  let queryDates: QueryDatesType = {
    date: getDateLabel(date),
  };

  if (isRange) {
    queryDates = {
      ...queryDates,
      endDate: getDateLabel(endDate),
    };
  }
  const
    {
      data: chartData,
      loading: chartLoading,
    } = useQuery(
      chartQuery,
      {
        variables: queryDates,
      },
    );

  const
    {
      data: activityData,
      loading: activityLoading,
    } = useQuery(
      activityQuery,
      {
        variables: queryDates,
        fetchPolicy: 'network-only',
      },
    );

  if (userError || (userData && userData.user && !userData.user.hasManagersActivityAccess)) {
    // checks that we can do API call to backend, means
    // JWT token is in place and it is valid.
    clearAuthCookies();
    return <Navigate to="/authentication/login/" />;
  }

  if (!(userData && userData.user)) {
    return '';
  }
  return (
    <div className="min-h-full">
      <DashboardSidebar
        user={userData.user}
      />

      <div className="flex flex-col pl-24">
        <main className="flex-1">
          <div className="flex py-6 items-center justify-between">
            <div className="text-xl font-medium leading-6 text-slate-900 sm:truncate">Managers Activity Overview</div>
            <div className="flex justify-center items-center">
              <div className="grid justify-items-stretch pr-4">
                <Switch
                  value={isRange}
                  className="flex justify-center"
                  onChange={() => setIsRange(!isRange)}
                />
                <span className="text-xs font-medium pt-1">Select Range</span>
              </div>
              <div>
                {isRange
                  ? (
                    <RangedDateNav
                      date={date}
                      endDate={endDate}
                      setDate={setDate}
                      setEndDate={setEndDate}
                    />
                  )

                  : <DailyDateNav date={date} setDate={setDate} />}
              </div>
            </div>
          </div>
          <div>
            {!chartData && chartLoading
              ? (<div>Loading Heatmap</div>)
              : (<Chart item={chartData && chartData.chart && chartData.chart.response} />)}
          </div>
          <div>
            {!activityData && activityLoading
              ? (<div>Loading Activity Data</div>)
              : (
                <ActivitiesLog items={activityData
                  && activityData.managersActivity
                  && activityData.managersActivity.response}
                />
              )}

          </div>
        </main>
      </div>
    </div>
  );
}
