import { useQuery } from '@apollo/client';
import { InstanceType } from '../../__generated__/graphql';
import { PINNED_INSTANCES_QUERY } from '../../api/queries/instances';
import PinnedInstanceCard from './PinnedInstaceCard';

function PinnedInstances() {
  const { data: instancesData } = useQuery(PINNED_INSTANCES_QUERY);

  return (
    instancesData && instancesData.pinnedInstances.response.length
      ? (
        <>
          <h2 className="text-slate-500 text-xs font-medium uppercase tracking-wide">Pinned Instances</h2>

          <ul className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3">
            {instancesData.pinnedInstances.response.map((item: InstanceType) => (
              <li
                key={item.id}
                className="relative col-span-1 flex shadow-sm rounded-md"
              >
                <PinnedInstanceCard item={item} />
              </li>
            ))}
          </ul>
        </>
      )
      : (
        <div className="hidden sm:block text-xs">Nothing is pinned. Use this feature to always have the projects you work on in your sight.</div>
      )

  );
}

export default PinnedInstances;
