import ErrorPageTempate from './ErrorPageTempate';

function Error403() {
  return (
    <ErrorPageTempate
      label="Error: Forbidden."
      code={403}
    />
  );
}

export default Error403;
