import { IconTrash } from '@tabler/icons-react';
import GoogleFontLoader from 'react-google-font-loader';
import { classNames } from '../../../../helpers/utils';

interface Props {
  family: string,
  variant: string,
  itemId: string,
  isDefault: boolean,
  isSelected: boolean | null,
  handleCardClick: (fontId?: string) => void,
  removeFont: (fontId: string) => void,
}

function FontCard(props: Props) {
  const {
    family, variant, itemId, isDefault, isSelected, handleCardClick, removeFont,
  } = props;
  const label = isDefault ? 'Default Font:' : 'Additinal Font:';
  return (
    <div>
      {family && variant && (
        <GoogleFontLoader
          fonts={[
            {
              font: family,
              weights: [variant],
            },
          ]}
        />
      )}
      <div className={classNames(isSelected && 'border-4 border-slate-300', 'flex rounded-lg')}>
        <div
          role="button"
          tabIndex={0}
          onClick={() => (handleCardClick(itemId))}
          className={classNames(isDefault ? 'bg-orange-500' : 'bg-slate-500', 'flex-shrink-0 flex items-center justify-center h-16 w-16 text-white text-3xl font-medium rounded-l-md')}
        >
          <span className="text-5xl" style={{ fontFamily: family }}>A</span>
        </div>

        <div
          className={classNames(!isSelected && 'border-t border-r border-b', 'flex-1 flex items-center justify-between  border-slate-200 bg-white rounded-r-md truncate')}
        >
          <div
            role="button"
            tabIndex={0}
            onClick={() => (handleCardClick(itemId))}
            className="cursor-pointer flex-1 px-4 py-2 text-sm truncate max-w-xl"
          >
            <div className="text-xs">
              {label}
            </div>
            <div className="text-base flex">
              <div>
                <span>{family}</span>
              </div>
              <div className="hidden sm:block">
                {': '}
                <span>{variant}</span>
              </div>
            </div>
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => removeFont(itemId)}
            className="flex-shrink-0 pr-4"
          >
            <div
              className="w-8 h-8 bg-white inline-flex items-center justify-center text-slate-400 rounded-full hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
            >
              <IconTrash className="w-5 h-5" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FontCard;
