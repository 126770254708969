import { ContainerType } from '../../../../__generated__/graphql';
import ContainerDetailsTable from './ContainerDetailsTable';
import ContainerLogTable from './ContainerLogTable';

interface Props {
  item: ContainerType,
}

export default function ContainerCard(props: Props) {
  const { item } = props;
  return (
    <div className="flex flex-col">
      <div id="styled-scroll" className="overflow-y-auto">
        <div className="align-middle">
          <div className="shadow border-b border-gray-200 sm:rounded-lg h-screen">
            <ContainerDetailsTable item={item} />
            <ContainerLogTable itemId={item.id} />
          </div>
        </div>
      </div>
    </div>
  );
}
