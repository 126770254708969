import {
  IconInfoCircle,
} from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css';

interface Props {
  text: string,
}

function InfoTooltip(props: Props) {
  const { text } = props;
  return (
    <Tippy content={text}>
      <span tabIndex={0} role="button">
        <IconInfoCircle
          className="h-5 w-5 text-slate-500 hover:text-slate-900"
          aria-hidden="true"
        />
      </span>
    </Tippy>
  );
}

export default InfoTooltip;
