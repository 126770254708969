import { NoteType, UserType } from '../../../../__generated__/graphql';
import { useAppDispatch } from '../../../../helpers/reduxHooks';
import { setItem } from '../../../../redux/notesSlice';
import NoteCard from './NoteCard';

interface Props {
  user: UserType,
  items: NoteType[],
}

export default function NotesFeed(props: Props) {
  const { items, user } = props;
  const dispatch = useAppDispatch();

  return (
    <ul className="divide-y divide-gray-200">
      {items.map((item) => (
        <li key={item.id} className="py-4" onClick={() => dispatch(setItem(item))}>
          <NoteCard
            item={item}
            user={user}
          />
        </li>
      ))}
    </ul>
  );
}
