import { ManagerActivityRecordType } from '../../__generated__/graphql';
import { classNames } from '../../helpers/utils';

function getActivityColor(actions: number) {
  if (actions <= 10) {
    return 'bg-blue-300';
  }
  if (actions <= 20) {
    return 'bg-blue-400';
  }
  if (actions <= 40) {
    return 'bg-blue-500';
  }
  if (actions <= 60) {
    return 'bg-blue-600';
  }
  if (actions <= 80) {
    return 'bg-blue-700';
  }
  if (actions <= 100) {
    return 'bg-blue-800';
  }

  return 'bg-blue-900';
}

interface Props {
  item: ManagerActivityRecordType,
}

function ActivityLogRecord(props: Props) {
  const { item } = props;
  const bgColor = getActivityColor(item.actions);
  return (
    <div className="flex h-12">
      <div
        className={classNames(
          bgColor,
          'flex-shrink-0 flex items-center justify-center w-4 rounded-l-md',
        )}
      />
      <div className="flex-1 flex items-center justify-between border-t border-r border-b border-slate-200 bg-white rounded-r-md truncate">
        <div className="flex-1 px-4 py-2 text-sm truncate">
          <div className="text-slate-900 font-medium hover:text-slate-600">
            {item.manager}
          </div>
          <p className="text-slate-500">
            {item.actions}
            {' '}
            actions
          </p>
        </div>
        <div className="flex-shrink-0 pr-2">
          <div
            className="w-8 h-8 bg-white inline-flex items-center justify-center text-slate-400 rounded-full bg-transparent hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          />
        </div>
      </div>
    </div>
  );
}

export default ActivityLogRecord;
