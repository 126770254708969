import {
  IconX, IconCircleX, IconCircleCheck, IconInfoCircle,
} from '@tabler/icons-react';
import { AlertType } from '../../constants';
import { classNames } from '../../helpers/utils';

interface AlertIconProps {
  alertType: AlertType,
}
function AlertIcon(props: AlertIconProps) {
  const { alertType } = props;
  switch (alertType) {
    case AlertType.ERROR:
      return <IconCircleX className="h-8 w-8 text-red-500" aria-hidden="true" />;
    case AlertType.SUCCESS:
      return <IconCircleCheck className="h-8 w-8 text-green-500" aria-hidden="true" />;
    case AlertType.INFO:
      return <IconInfoCircle className="h-8 w-8 text-blue-500" aria-hidden="true" />;
    default:
      return <IconCircleX className="h-8 w-8 text-red-500" aria-hidden="true" />;
  }
}

interface ReduxAlertProps {
  alertType: AlertType,
  messageList: string[],
  handleClose: () => void,
  className?: string,
}

export default function ReduxAlert(props: ReduxAlertProps) {
  const {
    alertType, handleClose, messageList, className,
  } = props;
  const isOneMessage = messageList.length === 1;
  let primaryColor;

  switch (alertType) {
    case AlertType.SUCCESS:
      primaryColor = 'bg-emerald-100';
      break;
    case AlertType.ERROR:
      primaryColor = 'bg-red-100';
      break;
    case AlertType.INFO:
      primaryColor = 'bg-blue-100';
      break;

    default:
      break;
  }

  return (
    // @ts-ignore
    <div className={classNames(className && className, `${primaryColor} p-4`)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <AlertIcon alertType={alertType} />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          {isOneMessage ? (
            <span className="mt-1 text-sm ">{messageList[0]}</span>
          )
            : (
              <ul className="list-disc pl-5 space-y-1">
                { }
                {!!messageList.length && messageList.map((item, index) => (
                  <li key={index} className="mt-1 text-sm ">{item}</li>
                ))}
              </ul>
            )}
        </div>
        <div className="ml-4 flex-shrink-0 flex items-center">
          <button
            className={classNames(primaryColor as string, 'rounded-md inline-flex')}
            onClick={() => { handleClose();} }
          >
            <IconX className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
