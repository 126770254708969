import { ActionLogType, ModxActionLogEventChoices } from '../../../../__generated__/graphql';
import { ACTION_EVENT } from '../../../../constants';
import { classNames, formatTimestamp } from '../../../../helpers/utils';

function convertTextWithNewLinesIntoDivs(inputText: string) {
  return inputText.split('\n').map((str, index) => <div key={index}>{str}</div>);
}

function getTriggerDetails(item: ActionLogType) {
  let details = '';
  if (item.primaryRecord) {
    if (item.primaryRecord.author) {
      details = `triggered by ${item.primaryRecord.author.username} at ${formatTimestamp(item.primaryRecord.createdAt)}`;
    } else {
      details = `triggered at ${formatTimestamp(item.primaryRecord.createdAt)}`;
    }
  } else if (item.author) {
    details = `triggered by ${item.author.username}`;
  }
  return details;
}

function getBadgeColor(actionEvent: ModxActionLogEventChoices) {
  let result;
  switch (actionEvent) {
    case ACTION_EVENT.EXECUTION:
      result = 'bg-blue-500';
      break;
    case ACTION_EVENT.SUCCESS:
      result = 'bg-emerald-500';
      break;
    case ACTION_EVENT.FAILURE:
      result = 'bg-red-400';
      break;
    default:
      result = 'bg-slate-500';
  }
  return result;
}

interface Props {
  items: ActionLogType[]
}

function ActionLogList(props: Props) {
  const { items } = props;

  return (
    <ul className="divide-y divide-slate-200">
      {items.map((item) => (
        <li
          key={item.id}
          className="relative bg-white py-5 px-4 hover:bg-slate-50"
        >
          <div className="flex space-x-3">
            <div className="min-w-0 flex-1">
              <div className="block focus:outline-none">
                <div className="text-sm font-medium text-slate-900">
                  <span className="pr-2 text-slate-500">{formatTimestamp(item.createdAt)}</span>
                  {item.actionType}
                  <div className={classNames(getBadgeColor(item.event), 'sm:ml-2 block sm:inline-flex w-fit items-center px-2 py-0.5 mr-2 my-0.5 rounded text-xs font-medium text-white')}>
                    {item.event}
                  </div>
                  <div className="inline-flex text-xs text-slate-500">
                    {getTriggerDetails(item)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <div className="text-xs sm:text-sm max-w-[35vh] text-clip overflow-hidden text-slate-700">{convertTextWithNewLinesIntoDivs(item.message)}</div>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default ActionLogList;
