/* eslint-disable func-names */
import { ResponsiveBar } from '@nivo/bar';
import { ActivityChartType } from '../../__generated__/graphql';

interface Props {
  item: ActivityChartType,
}

function Chart(props: Props) {
  const { item } = props;
  return (
    <div>
      <div className="text-lg font-medium leading-6 text-slate-700 sm:truncate py-2">Activities Overview</div>
      <div>
        {item && item?.keys?.length
          ? (
            <div className="h-96">
              <ResponsiveBar
                data={item.data}
                keys={item.keys as string[]}
                indexBy="instance"
                margin={{
                  top: 50, right: 130, bottom: 50, left: 60,
                }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                borderColor={{
                  from: 'color',
                  modifiers: [
                    [
                      'darker',
                      1.6,
                    ],
                  ],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'instance',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'actions',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [
                    [
                      'darker',
                      1.6,
                    ],
                  ],
                }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Daily Activity Chart"
              />
            </div>
          )
          : (
            <div className="text-slate-500">No data</div>
          )}
      </div>
    </div>
  );
}

export default Chart;
