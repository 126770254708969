import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!){
      tokenAuth(email: $email, password: $password){
        token
        refreshToken
      }
  }
`;

export const REFRESH_JWT_MUTATION = gql`
  mutation refreshToken($refreshToken: String!){
    refreshToken(refreshToken: $refreshToken)
    {
      token
      refreshToken
    }
  }
`;

export const REVOKE_REFRESH_TOKEN_MUTATION = gql`
  mutation revokeToken($refreshToken: String!){
    revokeToken(refreshToken: $refreshToken)
    {
      revoked
    }
  }
`;
