import { useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { GLOBAL_ACTIVATE_MODX_USERS_MUTATION,
  GLOBAL_DEACTIVATE_MODX_USERS_MUTATION, 
  GLOBAL_REMOVE_MODX_USERS_MUTATION,
} from '../../api/mutations/modxUsersManagement';
import { MODX_USERS_ACTIONS } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../helpers/reduxHooks';
import { updateSelectTheme } from '../../helpers/utils';
import { InfoAlert, ReduxAlert } from '../../layout/alerts';
import { discardAlert, setSuccessAlert } from '../../redux/alertSlice';
import { Activate, Deactivate, Remove } from './buttons';

function GlobalView() {
  const [usernames, setUsernames] = useState<SelectOption[]>([]);
  const [action, setAction] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const alertsState = useAppSelector((state) => state.alert);
  const dispatch = useAppDispatch();

  const [
    globalActivateModxUsersMutation, {
      loading: ActivateUsersLoading,
    }] = useMutation(GLOBAL_ACTIVATE_MODX_USERS_MUTATION);

  const [
    globalDeactivateModxUsersMutation, {
      loading: deactivateUsersLoading,
    }] = useMutation(GLOBAL_DEACTIVATE_MODX_USERS_MUTATION);

  const [
    globalRemoveModxUsersMutation, {
      loading: removeUsersLoading,
    }] = useMutation(GLOBAL_REMOVE_MODX_USERS_MUTATION);

  const mutationVariables = {
    users: usernames.map((item) => item.value),
  };

  const handleActionClick = (inputAction: string) => {
    if (action === inputAction) {
      if (!isConfirmed) {
        setIsConfirmed(true);
      }
    } else {
      setAction(inputAction);
      setIsConfirmed(false);
    }
  };

  useEffect(() => {
    if (isConfirmed) {
      switch (action) {
        case MODX_USERS_ACTIONS.ACTIVATE:
          globalActivateModxUsersMutation({
            variables: mutationVariables,
            onCompleted: () => {
              dispatch(setSuccessAlert({
                messages: ['Selected Users were Activated'],
              }));
            },
          });
          break;

        case MODX_USERS_ACTIONS.DEACTIVATE:
          globalDeactivateModxUsersMutation({
            variables: mutationVariables,
            onCompleted: () => {
              dispatch(setSuccessAlert({
                messages: ['Selected Users were Deactivated'],
              }));
            },
          });
          break;

        case MODX_USERS_ACTIONS.REMOVE:
          globalRemoveModxUsersMutation({
            variables: mutationVariables,
            onCompleted: () => {
              dispatch(setSuccessAlert({
                messages: ['Selected Users were Removed'],
              }));
            },
          });
          break;

        default:
          break;
      }
      setUsernames([]);
      setAction('');
      setIsConfirmed(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed, action]);

  return (
    <div className="mx-auto max-w-7xl px-4">
      <div className="h-[42rem] mt-6 grid grid-cols-2 gap-0.5 lg:mt-8">
        <div>
          <div className="mb-6 ">
            <div className="block text-sm font-medium text-slate-900">
              Please enter usernames here
            </div>
            <div className="mt-1">
              <CreatableSelect
                value={usernames || []}
                isMulti
                isClearable
                className="mt-1"
                placeholder='Type in username and press "Enter"'
                onChange={(e) => (e
                  ? setUsernames(e as SelectOption[])
                  : setUsernames([]))}
                theme={(theme) => updateSelectTheme(theme)}
                components={
                      {
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Menu: () => null,
                      }
                    }
              />
            </div>
          </div>
          {(ActivateUsersLoading || deactivateUsersLoading || removeUsersLoading) && (
            <InfoAlert message="Applying now. Please be patient. It takes few seconds per Instance." />
          )}
          {alertsState.alert
            && (
              <ReduxAlert
                className="rounded-md"
                key={alertsState.alert.id}
                alertType={alertsState.alert.alertType}
                messageList={alertsState.alert.messages}
                handleClose={() => { dispatch(discardAlert()); }}
              />
            )}
          {!!usernames.length && (
            <div>
              <div className="grid grid-cols-3 gap-0.5 lg:mt-8 text-slate-400">
                <Activate
                  handleClick={() => handleActionClick(MODX_USERS_ACTIONS.ACTIVATE)}
                  awaitsConfirmation={
                    action === MODX_USERS_ACTIONS.ACTIVATE && isConfirmed === false
                }
                />
                <Deactivate
                  handleClick={() => handleActionClick(MODX_USERS_ACTIONS.DEACTIVATE)}
                  awaitsConfirmation={
                    action === MODX_USERS_ACTIONS.DEACTIVATE && isConfirmed === false
                }
                />
                <Remove
                  handleClick={() => handleActionClick(MODX_USERS_ACTIONS.REMOVE)}
                  awaitsConfirmation={
                    action === MODX_USERS_ACTIONS.REMOVE && isConfirmed === false
                }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GlobalView;
