import { useMutation } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ActionLogType, FontType } from '../../../../__generated__/graphql';
import { FONTS_SET_MUTATION } from '../../../../api/mutations/fonts';
import { AVAILABLE_FONTS_QUERY, CURRENT_FONTS_QUERY } from '../../../../api/queries/fonts';
import { ACTION_EVENT } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { setErrorAlert, setInfoAlert, setSuccessAlert } from '../../../../redux/alertSlice';
import { openConfirmationModal, resetConfirmationModal } from '../../../../redux/dashboardSlice';
import { setFontsPayload, resetFontsState } from '../../../../redux/fontsSlice';

interface Props {
  currentFonts: FontType[],
  itemLatestActionLog: ActionLogType,
}

function ApplyChanges(props: Props) {
  const { currentFonts, itemLatestActionLog } = props;
  const confirmationModal = useAppSelector(state => state.dashboard.confirmationModal);
  const { instanceId } = useParams();
  const dispatch = useAppDispatch();

  const [fontsSetMutation, 
    { },
  ] = useMutation(FONTS_SET_MUTATION, {
    refetchQueries: [
      { query: AVAILABLE_FONTS_QUERY },
      { query: CURRENT_FONTS_QUERY, variables: { instance: instanceId } },
    ],
  });

  const handleClick = () => {
    dispatch(setFontsPayload(currentFonts));
    dispatch(openConfirmationModal({
      title: 'Confirm Instance Fonts Update',
    }));
  };

  const resetState = useCallback(() => {
    dispatch(resetFontsState());
    dispatch(resetConfirmationModal());
  }, [dispatch]);

  useEffect(() => {
    if (confirmationModal.actionApproved) {
      const fonts = currentFonts.map((item) => ({
        category: item.category,
        family: item.family,
        file: item.file,
        variant: item.variant,
        version: item.version,
      }));

      fontsSetMutation({
        variables: {
          instance: instanceId,
          fonts,
        },
      });
      resetState();
    } else if (confirmationModal.actionDeclined) {
      resetState();
    }
  }, [confirmationModal, fontsSetMutation, instanceId, currentFonts, resetState, dispatch]);

  useEffect(() => {
    if (confirmationModal.actionApproved 
      && itemLatestActionLog 
      && !itemLatestActionLog.isDismissed) {
      if (itemLatestActionLog.event === ACTION_EVENT.SUCCESS) {
        dispatch(setSuccessAlert({
          messages: ['The Font Set was successful'],
          instanceId: instanceId,
        }));
      }
      if (itemLatestActionLog.event === ACTION_EVENT.EXECUTION) {
        dispatch(setInfoAlert({
          messages: ['Applying Font Changes'],
          instanceId: instanceId,
        }));
      }
      if (itemLatestActionLog.event === ACTION_EVENT.FAILURE) {
        dispatch(setErrorAlert({
          messages: ['Font Setting failed to complete'],
          instanceId: instanceId,
        }));
      }
    }
  }, [confirmationModal.actionApproved, dispatch, instanceId, itemLatestActionLog]);

  return (
    <div className="flex h-full flex-col divide-y divide-slate-200">
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          onClick={() => handleClick()}
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-orange-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
        >
          Apply Changes
        </button>
      </div>
    </div>
  );
}

export default ApplyChanges;
