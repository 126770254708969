import { useEffect, useState } from 'react';
import { classNames } from '../../../../helpers/utils';

interface ClipboardIconProps {
  className: string,
}

function ClipboardIcon(props: ClipboardIconProps) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path
        strokeWidth="0"
        d="M5.5 13.5v-5a2 2 0 0 1 2-2l.447-.894A2 2 0 0 1 9.737 4.5h.527a2 2 0 0 1 1.789 1.106l.447.894a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2Z"
      />
      <path
        fill="none"
        strokeLinejoin="round"
        d="M12.5 6.5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2m5 0-.447-.894a2 2 0 0 0-1.79-1.106h-.527a2 2 0 0 0-1.789 1.106L7.5 6.5m5 0-1 1h-3l-1-1"
      />
    </svg>
  );
}

interface Props {
  code: string,
}

function CopyButton(props: Props) {
  const { code } = props;
  const [copyCount, setCopyCount] = useState(0);
  const copied = copyCount > 0;

  useEffect(() => {
    if (copyCount > 0) {
      const timeout = setTimeout(() => setCopyCount(0), 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copyCount]);

  return (
    <button
      type="button"
      className={classNames(
        copied
          ? 'bg-orange-400/10 ring-1 ring-inset ring-orange-400/20'
          : 'bg-white/5 hover:bg-white/7.5 dark:bg-white/2.5 dark:hover:bg-white/5',
        'group/button absolute top-3.5 right-4 overflow-hidden rounded-full py-1 pl-2 pr-3 text-2xs font-medium opacity-0 backdrop-blur transition focus:opacity-100 group-hover:opacity-100',

      )}
      onClick={() => {
        window.navigator.clipboard.writeText(code).then(() => {
          setCopyCount((count) => count + 1);
        });
      }}
    >
      <span
        aria-hidden={copied}
        className={classNames(
          //@ts-ignore
          copied && '-translate-y-1.5 opacity-0',
          'pointer-events-none flex items-center gap-0.5 text-slate-400 transition duration-300',
        )}
      >
        <ClipboardIcon className="h-5 w-5 fill-slate-500/20 stroke-slate-500 transition-colors group-hover/button:stroke-slate-400" />
        Copy
      </span>
      <span
        aria-hidden={!copied}
        className={classNames(
          //@ts-ignore
          !copied && 'translate-y-1.5 opacity-0',
          'pointer-events-none absolute inset-0 flex items-center justify-center text-orange-600 transition duration-300',
        )}
      >
        Copied!
      </span>
    </button>
  );
}

export default CopyButton;
