import { useQuery } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import {
  IconDotsVertical, IconRefresh, IconTerminal, IconLayoutGrid,
  IconPlayerPause, IconRotate, IconClock, IconDatabase, IconDatabaseOff, IconEdit,
  IconCircleX, IconPlayerPlay, IconCloud,
} from '@tabler/icons-react';
import { Fragment } from 'react';
import { InstanceType } from '../../../../__generated__/graphql';
import { USER_QUERY } from '../../../../api/queries/users';
import { ENTITY_STATUS } from '../../../../constants';
import { useAppDispatch } from '../../../../helpers/reduxHooks';
import { classNames } from '../../../../helpers/utils';
import { 
  STOP_INSTANCE_CONFIRMATION, START_INSTANCE_CONFIRMATION, REBUILD_CONFIRMATION, 
  STOP_DBA_CONFIRMATION, START_DBA_CONFIRMATION, BACKUP_CONFIRMATION, 
  REFRESH_CONFIRMATION, NORMALIZE_PERMISSIONS_CONFRIMATION, CANCEL_CRON_CONFRIMATION, 
  START_CRON_CONFRIMATION, GULP_BUILD_DEVELOPMENT_CONFIRMATION, GULP_BUILD_CONFIRMATION,
} from '../../../../redux/constants';
import { updateInstance } from '../../../../redux/instancesSlice';
import { ActionOptionParams } from './Overview';

interface Props {
  item: InstanceType,
  actionOptionHandler: (optionParams: ActionOptionParams) => void,
}

export default function ActionOptions(props: Props) {
  const { item, actionOptionHandler } = props;
  const dispatch = useAppDispatch();
  const { data: userData } = useQuery(USER_QUERY);

  const { user } = userData;

  return (
    <Menu as="div" className="relative inline-block text-left ml-auto">
      <div>
        <Menu.Button className="flex items-center text-slate-400 hover:text-slate-600">
          <span className="sr-only">Open options</span>
          <IconDotsVertical className="h-6 w-6" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          {user.isSuperuser && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    role="button"
                    tabIndex={0}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm',
                    )}
                    onClick={() => dispatch(updateInstance())}
                  >
                    <IconEdit className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    Edit Instance
                  </div>
                )}
              </Menu.Item>
              {
                item.status === ENTITY_STATUS.RUNNING
                  ? (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm',
                          )}
                          onClick={() => actionOptionHandler({
                            option: STOP_INSTANCE_CONFIRMATION,
                            title: 'Confirm Instance Stop',
                          })}
                        >
                          <IconPlayerPause className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          Stop Instance
                        </div>
                      )}
                    </Menu.Item>
                  )
                  : (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm',
                          )}
                          onClick={() => actionOptionHandler({
                            option: START_INSTANCE_CONFIRMATION,
                            title: 'Confirm Instance Start',
                          })}
                        >
                          <IconPlayerPlay className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          Start Instance
                        </div>
                      )}
                    </Menu.Item>
                  )
              }
              <Menu.Item>
                {({ active }) => (
                  <div
                    role="button"
                    tabIndex={0}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm',
                    )}
                    onClick={() => actionOptionHandler({
                      option: REBUILD_CONFIRMATION,
                      title: 'Confirm Instance Rebuild',
                    })}
                  >
                    <IconRotate className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    Rebuild Instance
                  </div>
                )}
              </Menu.Item>
              
              <Menu.Item>
                {({ active }) => (
                  <div
                    role="button"
                    tabIndex={0}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm',
                    )}
                    onClick={() => actionOptionHandler({
                      option: BACKUP_CONFIRMATION,
                      title: 'Confirm Instance Backup',
                    })}
                  >
                    <IconCloud className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    Backup Instance
                  </div>
                )}
              </Menu.Item>
            </div>

          )}
          <div className="py-1">
            
            <Menu.Item>
              {({ active }) => (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                  onClick={() => actionOptionHandler({
                    option: REFRESH_CONFIRMATION,
                    title: 'Confirm Instance Refresh',
                  })}
                >
                  <IconRefresh className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Refresh Instance Data
                </div>
              )}

            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                  onClick={() => actionOptionHandler({
                    option: NORMALIZE_PERMISSIONS_CONFRIMATION,
                    title: 'Confirm Normalize Files Permissions',
                  })}
                >
                  <IconTerminal className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Normalize Files Permissions
                </div>
              )}
            </Menu.Item>
            {item.dbaEnabled
              ? (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      role="button"
                      tabIndex={0}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm',
                      )}
                      onClick={() => actionOptionHandler({
                        option: STOP_DBA_CONFIRMATION,
                        title: 'Confirm Database Administration Panel Stop',
                      })}
                    >
                      <IconDatabaseOff className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                      Disable DBA GUI
                    </div>
                  )}
                </Menu.Item>
              )
              : (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      role="button"
                      tabIndex={0}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm',
                      )}
                      onClick={() => actionOptionHandler({
                        option: START_DBA_CONFIRMATION,
                        title: 'Confirm Database Administration Panel Start',
                      })}
                    >
                      <IconDatabase className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                      Enable DBA GUI
                    </div>
                  )}
                </Menu.Item>
              )
            }
            {item.cronEnabled
              ? (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      role="button"
                      tabIndex={0}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm',
                      )}
                      onClick={() => actionOptionHandler({
                        option: CANCEL_CRON_CONFRIMATION,
                        title: 'Confirm Cron Service Cancel ',
                      })}
                    >
                      <IconCircleX
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Cancel Cron Task
                    </div>
                  )}
                </Menu.Item>
              )
              : (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      role="button"
                      tabIndex={0}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm',
                      )}
                      onClick={() => actionOptionHandler({
                        option: START_CRON_CONFRIMATION,
                        title: 'Confirm Cron Service Start',
                      })}
                    >
                      <IconClock
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Start Cron Task
                    </div>
                  )}
                </Menu.Item>
              )}
            {item?.flavor?.name 
              && ['Tailwind V3', 'Tailwind V2'].includes(item?.flavor?.name)
              && (
                <>
                  {!item.flavor.isActive && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm',
                          )}
                          onClick={() => actionOptionHandler({
                            option: GULP_BUILD_DEVELOPMENT_CONFIRMATION,
                            title: 'Confirm Gulp Development Build',
                          })}
                        >
                          <IconLayoutGrid className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          Gulp Build (Development)
                        </div>
                      )}
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        role="button"
                        tabIndex={0}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm',
                        )}
                        onClick={() => actionOptionHandler({
                          option: GULP_BUILD_CONFIRMATION,
                          title: 'Confirm Gulp Build',
                        })}
                      >
                        <IconLayoutGrid className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                        Gulp Build
                      </div>
                    )}
                  </Menu.Item>
                </>
              )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
