import { createSlice } from '@reduxjs/toolkit';

interface InstancesState {
  showUpdate: boolean,
}

// Define the initial state using that type
const initialState: InstancesState = {
  showUpdate: false,
};

export const instancesSlice = createSlice({
  name: 'instances',
  initialState: initialState,
  reducers: {
    updateInstance: (state) => {
      state.showUpdate = true;
    },
    closeUpdateInstance: (state) => {
      state.showUpdate = false;
    },
  },
});

export const { updateInstance, closeUpdateInstance } = instancesSlice.actions;

export default instancesSlice.reducer;