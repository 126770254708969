import { IconUserX } from '@tabler/icons-react';
import { classNames } from '../../../helpers/utils';

interface Props {
  awaitsConfirmation: boolean,
  handleClick: React.MouseEventHandler<HTMLDivElement>,
}

function Remove(props: Props) {
  const { awaitsConfirmation, handleClick } = props;
  const title = awaitsConfirmation ? 'Click to confirm' : 'Remove';
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleClick}
      className={classNames(awaitsConfirmation ? 'bg-orange-400 hover:bg-orange-500 text-white text-base px-4' : 'px-8 bg-red-50 hover:text-slate-700 hover:bg-red-200', 'flex justify-center items-center py-8 cursor-pointer rounded-r-lg')}
    >
      <div className="flex items-center">
        {!awaitsConfirmation && (<IconUserX className="mr-3 h-8 w-8" aria-hidden="true" />)}
        <div className="font-medium">{title}</div>
      </div>
    </div>
  );
}

export default Remove;
