import { Link } from 'react-router-dom';

function NoView() {
  return (
    <div className="mx-auto max-w-7xl px-4">
      <div className="h-[42rem] mt-6 grid grid-cols-2 gap-0.5 lg:mt-8">
        <Link
          to="global/"
          className="flex justify-center items-center bg-slate-50 py-8 px-8 hover:bg-slate-100 cursor-pointer rounded-l-lg"
        >
          <div>
            <div className="font-medium text-lg">Global Users Management</div>
            <div className="text-sm">Applies changes to all active MODX Instances registered in the Database.</div>
          </div>
        </Link>
        <Link
          to="single/"
          className="flex justify-center items-center bg-slate-50 py-8 px-8 hover:bg-slate-100 cursor-pointer rounded-r-lg"
        >
          <div>
            <div className="font-medium text-lg">Single Instance Users Management</div>
            <div className="text-sm">Applies only to selected MODX Instance.</div>
            <div className="text-sm">Can show existing users.</div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default NoView;
