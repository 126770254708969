import { gql } from '@apollo/client';

export const MODX_INSTANCE_USERS_QUERY = gql`
query modxInstanceUsers($instance: ID!){
  modxInstanceUsers(
    instance: $instance){
      response
      errors{
        field
        messages
      }
  }
}
`;
