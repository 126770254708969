import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { FontType } from '../__generated__/graphql';

interface FontsState {
  isLoading: boolean,
  fontsToApply: FontType[],
}

// Define the initial state using that type
const initialState: FontsState = {
  isLoading: false,
  fontsToApply: [],
};

export const fontsSlice = createSlice({
  name: 'fonts',
  initialState: initialState,
  reducers: {
    setGoogleFontLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setGoogleFontNotLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setFontsPayload: (state, action: PayloadAction<FontType[]>) => {
      return {
        ...state,
        fontsToApply: action.payload,
      };
    },
    resetFontsState: (state) => {
      state.isLoading = false;
      state.fontsToApply = [];
    },
  },
});

export const {
  setGoogleFontLoading, setGoogleFontNotLoading, setFontsPayload, resetFontsState,
} = fontsSlice.actions;

export default fontsSlice.reducer;
