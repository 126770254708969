import { useQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DISMISS_ACTION_LOGS_MUTATION } from '../../../../api/mutations/actionLogs';
import { ACTION_LOGS_QUERY } from '../../../../api/queries/actionLogs';
import { INSTANCE_QUERY } from '../../../../api/queries/instances';
import { LoadingIndicator } from '../../../../layout';
import ActionLogList from './ActionLogList';

function ActionLog() {
  const { instanceId } = useParams();

  const {
    data: logsData,
    loading: logsLoading,
  } = useQuery(ACTION_LOGS_QUERY, {
    variables: { instance: instanceId },
    skip: !instanceId,
  });

  const [
    viewLogsMutation,
    { }] = useMutation(DISMISS_ACTION_LOGS_MUTATION, {
    refetchQueries: [{ query: INSTANCE_QUERY, variables: { instance: instanceId } }],
  });

  useEffect(() => {
    if (instanceId) {
      viewLogsMutation({ variables: { instance: instanceId } });
    }
  }, [instanceId, viewLogsMutation]);

  if (logsLoading) {
    return (<LoadingIndicator className="flex justify-center items-center pt-4" />);
  }

  return (
    <ActionLogList items={(logsData && logsData.actionLogs && logsData.actionLogs.response)
      ? logsData.actionLogs.response
      : []}
    />
  );
}

export default ActionLog;
