import {
  IconTemplate, IconNotes, IconCloud, IconNotebook, IconServer, IconTypography,
  IconFingerprint, IconBrandGit,
} from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { CompatibilityType } from '../../../__generated__/graphql';
import { VIEW_TAB } from '../../../constants';
import { classNames } from '../../../helpers/utils';

interface Props {
  currentTab: string,
  setCurrentTab: (value: string) => void,
  notesQuantity: number,
  unviewedActionLogsQuantity: number,
  compatibility: CompatibilityType,
}

export default function VerticalTabs(props: Props) {
  const {
    currentTab, setCurrentTab, notesQuantity, unviewedActionLogsQuantity, compatibility,
  } = props;
  const tabs = [
    {
      name: VIEW_TAB.OVERVIEW, icon: IconTemplate,
    },
    {
      name: VIEW_TAB.NOTES, icon: IconNotes,
    },
    {
      name: VIEW_TAB.INSTANCE_LOG, icon: IconServer,
    },
    {
      name: VIEW_TAB.INSTANCE_ENV_CONFIG, icon: IconFingerprint,
    },
    {
      name: VIEW_TAB.BACKUPS, icon: IconCloud,
    },
  ];

  if (compatibility && compatibility.googleFont) {
    tabs.push({
      name: VIEW_TAB.FONTS, icon: IconTypography,
    });
  }
  if (compatibility && compatibility.git) {
    tabs.push({
      name: VIEW_TAB.VERSION_CONTROL, icon: IconBrandGit,
    });
  }

  tabs.push({
    name: VIEW_TAB.ACTION_LOG, icon: IconNotebook,
  });

  return (
    <div>
      <nav className="space-y-1" aria-label="Sidebar">
        {tabs.map((tab) => (
          <Tippy key={tab.name} placement="left" content={tab.name}>
            <div
              role="button"
              tabIndex={0}
              key={tab.name}
              className={classNames(
                tab.name === currentTab ? 'bg-slate-500' : 'text-slate-600 hover:bg-slate-200 hover:text-slate-900',
                'group flex items-center px-3 py-2 text-sm font-medium shadow-md',
              )}
              onClick={() => setCurrentTab(tab.name)}
              aria-current={tab.name === currentTab ? 'page' : undefined}
            >
              <tab.icon
                className={classNames(
                  tab.name === currentTab ? 'text-white' : 'text-slate-400 group-hover:text-slate-500',
                  'h-8 w-8',
                )}
                aria-hidden="true"
              />
              {tab.name === VIEW_TAB.ACTION_LOG && unviewedActionLogsQuantity > 0 && (
                <span
                  className="hidden text-orange-600 bg-orange-100 -ml-4 -mb-2 py-0.5 px-2 rounded-full text-xs font-medium md:inline-block"
                >
                  {unviewedActionLogsQuantity}
                </span>
              )}

              {tab.name === VIEW_TAB.NOTES && notesQuantity > 0 && (
                <span
                  className="hidden text-orange-600 bg-orange-100 -ml-4 -mb-2 py-0.5 px-2 rounded-full text-xs font-medium md:inline-block"
                >
                  {notesQuantity}
                </span>
              )}
            </div>
          </Tippy>
        ))}
      </nav>
    </div>
  );
}
