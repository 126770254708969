import { ContainerType } from '../../../../__generated__/graphql';
import { ENTITY_STATUS } from '../../../../constants';
import { getContainerCardStatusColor, classNames, getRAMColorClass } from '../../../../helpers/utils';

interface Props {
  item: ContainerType,
}

export default function ContainerDetailsTable(props: Props) {
  const { item } = props;
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Container Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            RAM
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        <tr key={item.id}>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.name}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <span
              className={`${getContainerCardStatusColor(item.status)} text-white hidden px-1 py-0.5 mr-2 my-0.5 rounded text-xs font-medium md:inline-block`}
            >
              {item.status}
            </span>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
            {item.status === ENTITY_STATUS.RUNNING && (
            <>
              <div
                className={classNames(getRAMColorClass(item.ramUsagePercent as number), 'mr-1 flex-shrink-0 w-1.5 h-8 rounded')}
                aria-hidden="true"
              />
              <div>
                <div className="text-sm">
                  {item.ramUsagePercentLabel}
                </div>
                <div className="text-[0.6rem]">
                  {item.ramUsage}
                  {' '}
                  MB
                </div>
              </div>
            </>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
