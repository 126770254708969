import { Menu, Transition } from '@headlessui/react';
import {
  IconDotsVertical, IconPackage, IconGitPullRequest, 
  IconGitMerge, IconGitPullRequestClosed, IconPrompt,
} from '@tabler/icons-react';
import { Fragment } from 'react';
import { classNames } from '../../../../helpers/utils';
import { 
  APPLY_REMOTE_CHANGES_CONFIRMATION,
  STORE_LOCAL_CHANGES_CONFIRMATION,
  RESET_LOCAL_CHANGES_CONFIRMATION,
  GITIFY_EXTRACT_CONFIRMATION,
  NPM_INSTALL_CONFIRMATION,
} from '../../../../redux/constants';
import { ActionOptionParams } from './VersionControl';

interface Props {
  actionOptionHandler: (optionParams: ActionOptionParams) => void,
}

export default function ActionOptions(props: Props) {
  const { actionOptionHandler } = props;

  return (
    <Menu as="div" className="relative inline-block text-left ml-auto">
      <div>
        <Menu.Button className="flex items-center text-slate-400 hover:text-slate-600">
          <span className="sr-only">Open options</span>
          <IconDotsVertical className="h-6 w-6" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                  onClick={() => actionOptionHandler({
                    option: APPLY_REMOTE_CHANGES_CONFIRMATION,
                    title: 'Confirm that you want to apply changes stored in the repository.',
                  })}
                >
                  <IconGitMerge className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Apply Remote Changes
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                  onClick={() => actionOptionHandler({
                    option: STORE_LOCAL_CHANGES_CONFIRMATION,
                    title: 'Confirm that you want to push manager changes to the project repository.',
                  })}
                >
                  <IconGitPullRequest className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Store Local Changes
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                  onClick={() => actionOptionHandler({
                    option: RESET_LOCAL_CHANGES_CONFIRMATION,
                    title: 'Confirm that you want to discard files related changes since latest commit.',
                  })}
                >
                  <IconGitPullRequestClosed className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Reset Local Changes
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                  onClick={() => actionOptionHandler({
                    option: GITIFY_EXTRACT_CONFIRMATION,
                    title: 'Confirm Gitify Extraction',
                  })}
                >
                  <IconPackage className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Gitify Extract
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                  onClick={() => actionOptionHandler({
                    option: NPM_INSTALL_CONFIRMATION,
                    title: 'You are about to install all packages listed in theme package.json file. Please confirm.',
                  })}
                >
                  <IconPrompt className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Install NPM packages
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
