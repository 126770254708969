import { gql } from '@apollo/client';

export const ACTION_LOGS_QUERY = gql`
query actionLogs($instance: ID!){
  actionLogs(
    instance: $instance){
      response{
      id
      actionType
      author{
        username
      }
      createdAt
      event
      message
      primaryRecord{
        id
        createdAt
        author{
          username
        }
      }
    }
    errors{
      field
      messages
    }
  }
}
`;
