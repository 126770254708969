import { useQuery, useMutation } from '@apollo/client';
import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { NOTE_DELETE_MUTATION } from '../../../../api/mutations/notes';
import { INSTANCE_QUERY } from '../../../../api/queries/instances';
import {
  NOTES_QUERY,
} from '../../../../api/queries/notes';
import { USER_QUERY } from '../../../../api/queries/users';
import { useAppSelector, useAppDispatch } from '../../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../../layout';
import { resetConfirmationModal } from '../../../../redux/dashboardSlice';
import { resetNotesState } from '../../../../redux/notesSlice';
import CreateNote from './CreateNote';
import NotesFeed from './NotesFeed';
import UpdateNote from './UpdateNote';


export default function Notes() {
  const { instanceId } = useParams();
  const notesState = useAppSelector(state => state.notes);
  const confirmationModal = useAppSelector(state => state.dashboard.confirmationModal);
  const dispatch = useAppDispatch();
  const { data: userData } = useQuery(USER_QUERY);
  const {
    data: notesData,
    loading: notesLoading,
  } = useQuery(NOTES_QUERY, {
    variables: { instance: instanceId },
  });

  const [
    deleteNoteMutation,
    { },
  ] = useMutation(NOTE_DELETE_MUTATION, {
    refetchQueries: [
      { query: NOTES_QUERY, variables: { instance: instanceId } },
      { query: INSTANCE_QUERY, variables: { instance: instanceId } },
    ],
  });

  const { user } = userData;
  const notes = (notesData
    && notesData.notes && notesData.notes.response && notesData.notes.response.length)
    ? notesData.notes.response
    : [];

  const resetState = useCallback(() => {
    dispatch(resetNotesState());
    dispatch(resetConfirmationModal());
  }, [dispatch]);
  
  useEffect(() => {
    if (confirmationModal.actionApproved) {
      deleteNoteMutation({ variables:{ note: notesState?.item?.id } });
      resetState();
    } else if (confirmationModal.actionDeclined) {
      resetState();
    }
  }, [notesState, confirmationModal, resetState, deleteNoteMutation]);

  return (
    <div className="px-4 py-6 sm:space-y-0 sm:gap-4 sm:px-6 sm:py-4">
      {notesState.item && notesState.requestUpdate
        && <UpdateNote item={notesState.item} user={user} />
      }
      <>
        {!notesState.requestDelete && !notesState.requestUpdate && (
          <CreateNote user={user} />
        )}
        {notesLoading && <LoadingIndicator className="flex justify-center items-center" />}
        {!notesLoading && !!notes.length && (
          <NotesFeed
            items={notes}
            user={user}
          />
        )}
      </>
    </div>
  );
}
