import { IconCalendarEvent } from '@tabler/icons-react';
import { CommitType } from '../../../../__generated__/graphql';

interface Props {
  item: CommitType,
}

export default function CommitCard(props: Props) {
  const { item } = props;
  const createdAt = new Date(item.createdAt as string);
  const timestamp = `${createdAt.toDateString()} ${createdAt.toLocaleTimeString()}`;

  return (
    <div className="md:flex flex-start">
      <div className="bg-slate-500 w-6 h-6 flex items-center justify-center rounded-full -ml-3">
        <IconCalendarEvent className="text-white w-4 h-4" />
      </div>
      <div className="block p-4 rounded-lg shadow-lg bg-slate-100 max-w-md ml-6 mb-10">
        <div className="flex justify-between sm:mb-2">
          <div className="font-medium text-slate-500 text-xs">{item.authorName}</div>
          <div className="hidden sm:block font-medium text-slate-500 text-xs">{timestamp}</div>
        </div>
        <div className="sm:hidden font-medium text-slate-500 text-xs mb-2">{timestamp}</div>
        <p className="text-sm">{item.title}</p>
      </div>
    </div>
  );
}
