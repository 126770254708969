import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UserType } from '../../../../__generated__/graphql';
import { NOTE_MUTATION } from '../../../../api/mutations/notes';
import { INSTANCE_QUERY } from '../../../../api/queries/instances';
import { NOTES_QUERY } from '../../../../api/queries/notes';
import { useAppDispatch } from '../../../../helpers/reduxHooks';
import { getInitials } from '../../../../helpers/utils';
import { setSuccessAlert } from '../../../../redux/alertSlice';

interface Props {
  user: UserType,
}

export default function CreateNote(props: Props) {
  const { user } = props;
  const { instanceId } = useParams();
  const dispatch = useAppDispatch();
  
  const [
    noteMutation,
    {
      data: noteData,
    }] = useMutation(NOTE_MUTATION, {
    refetchQueries: [
      { query: INSTANCE_QUERY, variables: { instance: instanceId } },
      { query: NOTES_QUERY, variables: { instance: instanceId } },
    ],
  });

  const schema = yup.object({
    body: yup.string().required('Requried'),
  });

  const {
    handleSubmit,
    handleChange,
    values: formikValues,
    resetForm,
  } = useFormik({
    initialValues: {
      body: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        instance: instanceId,
        body: values.body,
        author: user.id,
      };
      noteMutation({
        variables,
      });
      resetForm();
    },
  });

  useEffect(() => {
    if (
      noteData
      && noteData.note
      && noteData.note.response
    ) {
      dispatch(
        setSuccessAlert({
          messages: ['The Note was created'],
          instanceId: instanceId as string,
        }),
      );
    }
  }, [noteData, dispatch, instanceId]);

  return (
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0">
        <span className="bg-orange-500 shadow-lg inline-flex items-center justify-center h-8 w-8 rounded">
          <span className="text-sm font-medium leading-none text-white">{getInitials(user.username as string)}</span>
        </span>
      </div>
      <div className="min-w-0 flex-1">
        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium">{user.username}</h3>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="relative pt-4">
          <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-orange-500 focus-within:ring-1 focus-within:ring-orange-500">
            <textarea
              rows={3}
              name="body"
              id="body"
              className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
              placeholder="Add note..."
              value={formikValues.body}
              onChange={handleChange}
            />
            {/* Spacer element to match the height of the toolbar */}
            <div className="py-2" aria-hidden="true">
              {/* Matches height of button in toolbar (1px border + 36px content height) */}
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
            <div className="flex-shrink-0">
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700"
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
