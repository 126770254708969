import { gql } from '@apollo/client';

export const SERVERS_QUERY = gql`
  query servers($hideFull: Boolean){
    servers(hideFull: $hideFull){
      id
      domain
      isHost
      instancesLeft
      serverLabel
    }
  }
`;
