import { Switch as HeadlessSwitch } from '@headlessui/react';
import { classNames } from '../../helpers/utils';

interface Props {
  value: boolean,
  onChange: (value: boolean) => void,
  className: string,
  isLoading?: boolean,
}

export default function Switch(props: Props) {
  const {
    value, onChange, className, isLoading,
  } = props;

  let switchColor = 'bg-gray-400';
  if (isLoading) {
    switchColor = 'bg-blue-500';
  } else if (value) {
    switchColor = 'bg-orange-500';
  }

  return (
    <HeadlessSwitch.Group as="div" className={className}>
      <HeadlessSwitch
        checked={value}
        onChange={onChange}
        className={`${switchColor} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
      >
        <span
          aria-hidden="true"
          className={classNames(
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200',
          )}
        />
      </HeadlessSwitch>
    </HeadlessSwitch.Group>
  );
}
