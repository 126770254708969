import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { NoteType } from '../__generated__/graphql';


interface NotesState {
  item: NoteType | null,
  requestUpdate: boolean,
  requestDelete: boolean,
  requestDeleteConfirmed: boolean,
}

// Define the initial state using that type
const initialState: NotesState = {
  item: null,
  requestUpdate: false,
  requestDelete: false,
  requestDeleteConfirmed: false,
};

export const notesSlice = createSlice({
  name: 'notes',
  initialState: initialState,
  reducers: {
    setItem: (state, action: PayloadAction<NoteType>) => {
      state.item = action.payload;
    },
    setRequestUpdate: (state, action: PayloadAction<boolean>) => {
      state.requestUpdate = action.payload;
    },
    setRequestDelete: (state, action: PayloadAction<boolean>) => {
      state.requestDelete = action.payload;
    },
    resetNotesState: (state) => {
      state.item = null;
      state.requestUpdate = false;
      state.requestDelete = false;
      state.requestDeleteConfirmed = false;
    },
  },
});

export const {
  setRequestUpdate, setRequestDelete, resetNotesState, setItem,
} = notesSlice.actions;

export default notesSlice.reducer;
