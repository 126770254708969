import { useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PHP_VERSION_QUERY } from '../../../../../api/queries/instances';
import { LoadingIndicator } from '../../../../../layout';

export default function ShowPHPVersionButton() {
  const [requestTriggered, setRequestTriggered] = useState(false);
  const { instanceId } = useParams();

  const [
    getVersion, {
      data,
      loading,
    }] = useLazyQuery(PHP_VERSION_QUERY);

  useEffect(() => {
    if (requestTriggered) {
      getVersion({ variables: { instance: instanceId } });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestTriggered]);

  if (loading) {
    return <LoadingIndicator className="flex pt-2" />;
  }

  return (
    <>
      {!requestTriggered && (
        <button
          type="button"
          onClick={() => setRequestTriggered(true)}
          className="inline-flex items-center rounded border border-slate-300 bg-white px-2.5 text-xs font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
        >
          Show
        </button>
      )}
      {(requestTriggered && !loading && data && data.phpVersion && (
        <div>{data.phpVersion.response}</div>
      ))}
    </>
  );
}
