import { useQuery } from '@apollo/client';
import { CONTAINER_LOG_QUERY } from '../../../../api/queries/containers';
import config from '../../../../config';
import { LoadingIndicator } from '../../../../layout';

interface Props {
  itemId: string,
}

export default function ContainerLogTable(props: Props) {
  const { itemId } = props;

  const
    {
      data: logData,
      loading: logLoading,
    } = useQuery(
      CONTAINER_LOG_QUERY,
      {
        variables: { container: itemId },
        pollInterval: config.REFETCH_LOGS_EVERY,
      },
    );

  if (logLoading) {
    return <LoadingIndicator className="flex justify-center items-center" />;
  }

  return (
    <table className="min-w-full mt-1 border-t-2 divide-y overflow-x-auto overflow-y-auto">
      <tbody>
        {logData
          && !!logData.containerLog.response.length
          && logData.containerLog.response.map((line: string, index: number) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}>
              <td className="px-6 py-4 text-sm text-slate-600 break-all">{line}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
