import {
  IconChevronRight, IconExternalLink, IconShield, IconDatabase,
} from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { Link, useParams } from 'react-router-dom';
import { InstanceType } from '../../../__generated__/graphql';
import { classNames, getInitials, getRAMColorClass } from '../../../helpers/utils';

interface Props {
  items: InstanceType[] | undefined,
}

function MobileInstancesList(props: Props) {
  const { items } = props;
  const { tab } = useParams();
  return (
    <div className="px-4 sm:px-6">
      <ul className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3">
        {items?.map((item) => (
          <li key={item.id} className="relative col-span-1 flex shadow-sm rounded-md">
            <Link to={`/dashboard/${tab}/${item.id}/`} className="flex">
              <div
                className={classNames(
                  getRAMColorClass(Number(item.phpRamUsagePercent), Number(item.dbRamUsagePercent)),
                  'cursor-pointer flex-shrink-0 flex items-center justify-center w-16 text-white text-3xl font-medium rounded-l-md',
                )}
              >
                {getInitials(item.siteName)}
              </div>
            </Link>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-slate-200 bg-white rounded-r-md truncate">
              <Link to={`/dashboard/${tab}/${item.id}/`} className="cursor-pointer flex-1 px-4 py-2 text-sm truncate">
                <p className="text-slate-900 text-base">
                  {item.siteName}
                </p>
                <p className="text-slate-500">
                  {item.mainDomain}
                </p>
              </Link>
              <Tippy content="Go to Site">
                <a target="_blank" href={`http://${item.mainDomain}`} rel="noopener noreferrer" className="pl-1 icon">
                  <IconExternalLink className="h-5 w-5 text-slate-400 hover:text-slate-600 cursor-pointer" aria-hidden="true" />
                </a>
              </Tippy>
              <Tippy content="Go to Manager Panel">
                <a target="_blank" href={`http://${item.mainDomain}/luxadmin/`} rel="noopener noreferrer" className="pl-1 icon">
                  <IconShield className="h-5 w-5 text-slate-400 hover:text-slate-600 cursor-pointer" aria-hidden="true" />
                </a>
              </Tippy>
              {item.dbaEnabled && (
                <Tippy content="Go to Database Admin Panel">
                  <a target="_blank" href={`http://dba.${item.mainDomain}/`} rel="noopener noreferrer" className="pl-1 icon">
                    <IconDatabase className="ml-1 h-5 w-5  text-slate-400 hover:text-slate-600 cursor-pointer" aria-hidden="true" />
                  </a>
                </Tippy>
              )}
              <Tippy content="Show details">
                <Link to={`/dashboard/${tab}/${item.id}/`}>
                  <IconChevronRight
                    className="ml-1 h-5 w-5 text-slate-400 hover:text-slate-600 cursor-pointer"
                    aria-hidden="true"
                  />
                </Link>
              </Tippy>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MobileInstancesList;
