import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useState } from 'react';
import { getDateLabel } from '../../../helpers/utils';
import DatePicker from './DatePicker';

interface Props {
  date: Date,
  setDate: (value: Date) => void,
}

function DailyDateNav(props: Props) {
  const { date, setDate } = props;
  const [showCalendar, setShowCalendar] = useState(false);

  const setPreviousDay = () => {
    const shiftedDate = new Date(date);
    shiftedDate.setDate(shiftedDate.getDate() - 1);
    setDate(shiftedDate);
  };

  const setNextDay = () => {
    const shiftedDate = new Date(date);
    shiftedDate.setDate(shiftedDate.getDate() + 1);
    setDate(shiftedDate);
  };

  return (
    <div className="w-48 py-2 sm:py-0 relative">
      <span className="h-12 w-[180px] relative z-0 flex justify-center sm:inline-flex sm:justify-start rounded-md">
        <button
          type="button"
          onClick={() => setPreviousDay()}
          className="bg-white hover:bg-gray-50 relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300  text-sm font-medium text-gray-500"
        >
          <span className="sr-only">Previous Day</span>
          <IconChevronLeft className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={() => setShowCalendar(!showCalendar)}
          className="-ml-px relative w-full inline-flex justify-center items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {getDateLabel(date)}
        </button>
        <button
          type="button"
          onClick={() => setNextDay()}
          className="bg-white hover:bg-gray-50 relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300  text-sm font-medium text-gray-500"
        >
          <span className="sr-only">Next Day</span>
          <IconChevronRight className="h-5 w-5" aria-hidden="true" />
        </button>
      </span>
      {showCalendar && (
        <DatePicker
          hideCalendar={() => setShowCalendar(false)}
          className="absolute pr-4 sm:right-0 z-10"
          date={date}
          setDate={setDate}
        />
      )}
    </div>
  );
}

export default DailyDateNav;
