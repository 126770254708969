import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  INSTANCE_ENV_CONFIG_QUERY,
} from '../../../../api/queries/instances';
import { LoadingIndicator } from '../../../../layout';
import CopyButton from './CopyButton';

function EnvConfiguration() {
  const { instanceId } = useParams();

  const {
    data: configData,
    loading: configLoading,
  } = useQuery(INSTANCE_ENV_CONFIG_QUERY, { variables: { instance: instanceId } });

  if (configLoading) {
    return (<LoadingIndicator className="flex justify-center items-center pt-4" />);
  }

  if (configData && configData.envConfiguration
    && configData.envConfiguration.response) {
    return (
      <div className="not-prose my-2 overflow-hidden">
        <div className="group dark:bg-white/2.5">
          <div className="relative">
            <pre className="overflow-x-auto p-4 text-xs">{configData.envConfiguration.response}</pre>
            <CopyButton code={configData.envConfiguration.response} />
          </div>
        </div>
      </div>
    );
  }
}

export default EnvConfiguration;
