import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { LOGIN_MUTATION } from '../../api/mutations/users';
import { setAuthCookies } from '../../helpers/auth';
import { AuthLayout } from '../../layout';
import { Input } from '../../layout/fields';

function Login() {
  const navigate = useNavigate();
  const [
    login,
    {
      data: loginData,
      loading: loginLoading,
      error: loginError,
    }] = useMutation(LOGIN_MUTATION);

  const schema = yup.object({
    email: yup.string().required('Email is Required'),
    password: yup.string().required('Password is Required'),
  });

  const {
    handleSubmit,
    handleChange,
    touched,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
      };
      login({ variables });
    },
  });

  useEffect(() => {
    if (loginData) {
      const innerData = loginData[Object.keys(loginData)[0]];
      if (innerData && innerData.token) {
        setAuthCookies(innerData.token, innerData.refreshToken);
        navigate('/dashboard/active/');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  return (
    <AuthLayout>
      {loginError && <div className="bg-red-100 text-red-900 rounded-md p-2 text-sm mb-4 " role="alert">{loginError.message}</div>}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <Input
            id="email"
            name="email"
            type="text"
            autoComplete="email"
            //@ts-ignore
            errorMessage={touched.email && formikErrors.email}
            label="Email"
            onChange={handleChange}
          />
        </div>

        <div className="space-y-1">
          <Input
            id="password"
            name="password"
            type="password"
            autoComplete="password"
            //@ts-ignore
            errorMessage={touched.password && formikErrors.password}
            label="Password"
            onChange={handleChange}
          />
        </div>

        <div>
          <button
            type="submit"
            disabled={loginLoading}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 transition duration-150 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-600"
          >
            Log in
          </button>
        </div>
      </form>
    </AuthLayout>
  );
}

export default Login;
