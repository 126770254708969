import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { BackupType } from '../../../__generated__/graphql';
import { BACKUPS_QUERY } from '../../../api/queries/backups';
import { formatTimestamp } from '../../../helpers/utils';
import { LoadingIndicator, Badge } from '../../../layout';

function Backups() {
  const { instanceId } = useParams();

  const {
    data: backupsData,
    loading: backupsLoading,
  } = useQuery(BACKUPS_QUERY, {
    variables: { instance: instanceId },
  });

  if (backupsLoading) {
    return (<LoadingIndicator className="flex justify-center items-center pt-4" />);
  }
  return (
    <div className="align-middle inline-block min-w-full border-b border-slate-200">
      <table className="min-w-full">
        <thead>
          <tr className="border-t border-slate-200">
            <th className="px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">
              Name
            </th>
            <th className="hidden md:table-cell px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">
              Type
            </th>
            <th className="hidden md:table-cell px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">
              Size
            </th>
            <th className="hidden md:table-cell px-6 py-3 border-b border-slate-200 bg-slate-50 text-left text-xs font-medium text-slate-500 uppercase tracking-wider">
              Created At
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-800">
          {backupsData && backupsData.backups && backupsData.backups.response 
            && backupsData.backups.response.map((item: BackupType) => (
              <tr key={item.id} className="hover:bg-slate-50">
                <td className="px-6 py-3 whitespace-nowrap text-sm">
                  {item.fileName}
                </td>
                <td className="px-6 py-3 text-sm font-medium">
                  <Badge label={item.fileType} />
                </td>
                <td className="px-6 py-3 text-sm ">
                  {item.size}
                </td>
                <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm font-medium">
                  {formatTimestamp(item.createdAt)}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default Backups;
