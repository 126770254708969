import { gql } from '@apollo/client';

export const DISMISS_ACTION_LOG_MUTATION = gql`
mutation dismissActionLog(
  $actionLog: ID!,
  ){
    dismissActionLog(input:{
      actionLog: $actionLog
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const DISMISS_ACTION_LOGS_MUTATION = gql`
mutation dismissActionLogs(
  $instance: ID!,
  ){
    dismissActionLogs(input:{
      instance: $instance
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;
