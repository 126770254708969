import { classNames } from '../helpers/utils';

interface Props {
  bgColor?: string,
  label: string,
}

export default function Badge(props: Props) {
  const { bgColor, label } = props;

  return (
    <span className={classNames(bgColor || 'bg-slate-500', ' inline-flex items-center px-2 py-0.5 mr-2 my-0.5 rounded text-xs font-medium text-white')}>
      {label}
    </span>
  );
}
