import { useMutation, useQuery } from '@apollo/client';
import { IconPinnedOff, IconPinned } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useParams } from 'react-router-dom';
import { InstanceType } from '../../../../__generated__/graphql';
import { UNPIN_INSTANCE_MUTATION, PIN_INSTANCE_MUTATION } from '../../../../api/mutations/instances';
import { PAGINATED_INSTANCES_QUERY, PINNED_INSTANCES_QUERY } from '../../../../api/queries/instances';
import { useAppSelector } from '../../../../helpers/reduxHooks';

interface Props {
  item: InstanceType,
}

function PinAction({ item }: Props) {
  const instancesQueryAttributesState = useAppSelector(
    (state) => state.instancesQueryAttributes,
  );
  const { tab } = useParams();

  const [
    unpinInstanceMutation,
    { }] = useMutation(UNPIN_INSTANCE_MUTATION, {
    refetchQueries: [
      { query: PINNED_INSTANCES_QUERY },
      {
        query: PAGINATED_INSTANCES_QUERY,
        variables: {
          page: instancesQueryAttributesState.page,
          serverDomain: instancesQueryAttributesState.serverDomain,
          name: instancesQueryAttributesState.name,
          category: tab?.toUpperCase(),
        },
      },
    ],
  });

  const [
    pinInstanceMutation,
    { }] = useMutation(PIN_INSTANCE_MUTATION, {
    refetchQueries: [
      { query: PINNED_INSTANCES_QUERY },
      {
        query: PAGINATED_INSTANCES_QUERY,
        variables: {
          page: instancesQueryAttributesState.page,
          serverDomain: instancesQueryAttributesState.serverDomain,
          name: instancesQueryAttributesState.name,
          category: tab?.toUpperCase(),
        },
      },
    ],
  });

  const { data: instancesData } = useQuery(PINNED_INSTANCES_QUERY);


  const isThisInstancePinned = !!instancesData.pinnedInstances.response.find(
    (instance: InstanceType) => instance.id === item.id,
  );

  return (
    <>
      {isThisInstancePinned ? (
        <div
          role="button"
          tabIndex={0}
          onClick={
            () => unpinInstanceMutation({ variables: { instance: item.id } })
          }
          className="mt-0.5"
        >
          <Tippy content="Unpin Instance">
            <button
              className="w-6 h-8 bg-white inline-flex justify-center text-slate-400 rounded-full hover:text-slate-500"
            >
              <IconPinnedOff className="w-5 h-5" aria-hidden="true" />
            </button>
          </Tippy>
        </div>
      ) : (
        <div
          role="button"
          tabIndex={0}
          onClick={
            () => pinInstanceMutation({ variables: { instance: item.id } })
          }
          className="mt-0.5"
        >
          <Tippy content="Pin Instance">
            <button
              className="w-6 h-8 bg-white inline-flex justify-center text-slate-400 rounded-full hover:text-slate-500"
            >
              <IconPinned className="w-5 h-5" aria-hidden="true" />
            </button>
          </Tippy>
        </div>
      )}
      <div />
    </>
  );
}

export default PinAction;
