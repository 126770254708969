import { configureStore } from '@reduxjs/toolkit';
import alertReducer from './redux/alertSlice';
import dashboardReducer from './redux/dashboardSlice';
import fontsReducer from './redux/fontsSlice';
import instancesQueryAttributesReducer from './redux/instancesQueryAttributesSlice';
import instancesReducer from './redux/instancesSlice';
import notesReducer from './redux/notesSlice';

export const store = configureStore({
  reducer: {
    alert: alertReducer,
    instancesQueryAttributes: instancesQueryAttributesReducer,
    notes: notesReducer,
    dashboard: dashboardReducer,
    fonts: fontsReducer,
    instances: instancesReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;