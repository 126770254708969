import { IconCalendarStats } from '@tabler/icons-react';
import { useState } from 'react';
import { getDateLabel } from '../../../helpers/utils';
import DatePicker from './DatePicker';

interface Props {
  date: Date,
  setDate: (value: Date) => void,
  endDate: Date,
  setEndDate: (value: Date) => void,
}

function DailyDateNav(props: Props) {
  const {
    date, endDate, setDate, setEndDate,
  } = props;
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <div className="w-48 py-2 sm:py-0 relative">
      <span className="h-12 relative z-0 flex justify-center sm:inline-flex sm:justify-start rounded-md">
        <button
          type="button"
          onClick={() => setShowCalendar(!showCalendar)}
          className="-ml-px w-[180px] relative flex justify-start items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          <div className="flex items-center">
            <div className="pr-6">
              <IconCalendarStats className="h-5 w-5" aria-hidden="true" />

            </div>
            <div>
              <div>{getDateLabel(date)}</div>
              <div>{getDateLabel(endDate)}</div>
            </div>
          </div>
        </button>
      </span>
      {showCalendar && (
        <DatePicker
          hideCalendar={() => setShowCalendar(false)}
          className="absolute pr-4 sm:right-0 z-10"
          date={[date, endDate]}
          setDate={setDate}
          setEndDate={setEndDate}
        />
      )}
    </div>
  );
}

export default DailyDateNav;
