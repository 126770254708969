interface ConfigType {
  API_HOST?: string,
  OLD_FE?: string,
  API_URL?: string,
  ROOT_URL?: string,
  REFETCH_DASHBOARD_ITEMS_EVERY?: number,
  REFETCH_INSTANCE_DETAILS_EVERY?: number,
  REFETCH_LOGS_EVERY?: number,
  DEFAULT_DEBOUNCE_TIMEOUT?: number,
}
const config: ConfigType = {};

switch (window.location.hostname) {
  case 'luxadmin.lux-medien.com':
    config.API_HOST = 'https://api.luxadmin.lux-medien.com';
    break;
  case 'luxadmin.test01.lux-medien.com':
    config.API_HOST = 'https://api.luxadmin.test01.lux-medien.com';
    break;
  default:
    config.API_HOST = 'http://localhost:8000';
    config.OLD_FE = 'http://localhost:3000';
    break;
}

config.API_URL = `${config.API_HOST}/graphql/`;
config.ROOT_URL = window.location.origin;

// How often update dashboard instances. Defaults to 60 seconds
config.REFETCH_DASHBOARD_ITEMS_EVERY = 60000;
config.REFETCH_INSTANCE_DETAILS_EVERY = 20000;
config.REFETCH_LOGS_EVERY = 20000;
config.DEFAULT_DEBOUNCE_TIMEOUT = 500;

export default config;
