import { gql } from '@apollo/client';

export const CONTAINER_LOG_QUERY = gql`
 query containerLog($container: ID!) {
  containerLog(
    container: $container
  ){
    errors{
      field
      messages
    }
    response
  }
}
`;
