import {
  BrowserRouter as Router, Route, Navigate, Routes,
} from 'react-router-dom';
import { Home, ManagersActivity, MODXUsersManagement } from './pages';
import { Login } from './pages/auth';
import {
  Error400, Error401, Error403, Error404, Error500, Error503,
} from './pages/errorPages';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/dashboard/:tab" element={<Home />} />
        <Route path="/dashboard/:tab/:instanceId/" element={<Home />} />
        <Route path="/managers-activity" element={<ManagersActivity />} />
        <Route path="/managers-management/" element={<MODXUsersManagement />} />
        <Route path="/managers-management/:view" element={<MODXUsersManagement />} />
        <Route path="/authentication/login" element={<Login />} />
        <Route path="/400" element={<Error400 />} />
        <Route path="/401" element={<Error401 />} />
        <Route path="/403" element={<Error403 />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="/500" element={<Error500 />} />
        <Route path="/503" element={<Error503 />} />
        <Route path="*" element={<Navigate replace to="/dashboard/active/" />} />
      </Routes>
    </Router>
  );
}

export default App;
